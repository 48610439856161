<template>
  <div>
    <div class="header" v-if="!mobileType">
      <div class="header-sub logo" v-show="!mobileType" >
        <img class="logo-item" src="/img/img/umeyelogo1.png">
      </div>
      <div class="header-sub right-sub">
        <div class="header-item">简体中文</div>
        <div class="header-item">QQ</div>
        <div class="header-item">微信</div>
        <div class="header-item">意见反馈</div>
        <div class="header-item"><a href="/app#appdownload">APP下载</a></div>
      </div>
      <div :style="{'clear':'both','height':'40px'}"></div>
      <div @mouseleave="out">
        <div class="menu">
          <div class="left-icon"></div>
          <div class="menu-nav">
            <div @mouseover="() => hold(-1)" class="menu-item">
              <a href="/">首页</a>
            </div>
            <div :key="index" v-for="(item, index) in main_menu" class="menu-item" @mouseover="() => hold(index)">
              <div>
                <a :href="item.href">{{item.name}}</a>
              </div>
              <i class="open-menu el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="right-icon">
            <div @click="goLogin" class="go-login">登录平台</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="mobileType" :style="{'width':'100vw','height':'53px'}"></div>
    <div v-if="mobileType" class="mb-header">
      <!-- <div class="header-sub logo">
        <img class="logo-item" src="/img/img/umeyelogo1.png">
      </div> -->
      <div class="mb-menu-icon " :class="{'openmenu': openMenu}">
        <i class="el-icon-menu" @click="toggleMobileMenu"></i>
      </div>
      <div :class="{'hide': !openMenu}"  class="mb-menu">
        <div class="mb-top-box"></div>
        <a class="mb-menu-item" href="/">首页</a>
        <div 
          :key="index" v-for="(item, index) in main_menu"
          :class="{'open': showMenu.includes(index)}" 
          class="mb-menu-item" 
          @click="toggleMobileMenuList(index, showMenu.includes(index))"
        >
          <a :href="item.href">{{item.name}}</a>
        </div>
        <div @click="goLogin" class="md-login"><div>登录开放平台</div></div>
        <div class="md-login-shadow"></div>
      </div>
    </div>
    <div  v-show="mobileType && openMenu" :class="{'openmenu': openShadow}" @touchmove="touchmove"  class="back-shadow"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: -1,
      openMenu: false,
      mobileType : false,
      openShadow: false,
      showMenu: [],
      subMenu: [],
      main_menu : [
        {
          name: "云平台",
          href: "/cloud",
        },{
          name: "硬件接入",
          href: "/hardware",
        },{
          name: "APP开发",
          href: "/app",
        },{
          name: "服务部署",
          href: "/service",
        },{
          name: "方案&案例",
          href: "/case",
        },{
          name: "增值服务",
          href: "/addserver",
        },{
          name: "关于我们",
          href: "/introduce",
        },
      ],
      intervalNum: 0,
    };
          
  },
  watch : {
    openMenu (to){
      setTimeout(() => {
        this.openShadow = to
      },10)
    }
  },
  destroyed(){
    clearInterval(this.intervalNum)
  },
  mounted() {
    this.checkWindow();
    window.addEventListener('resize',this.checkWindow);
    var intervalCount = 0
    this.intervalNum = setInterval(() => {
      this.checkWindow();
      intervalCount = intervalCount + 1;
      if(intervalCount > 100){
        clearInterval(this.intervalNum)
      }
    }, 500)
  },
  methods : {
    goLogin(){
      window.location.href = "http://open.umeye.com"
    },
    touchmove(e){
      if(this.openMenu){
        e.preventDefault();
      }
    },
    toggleMobileMenuList(index, isOpen){
      if(isOpen){
        this.showMenu = this.showMenu.filter(item => item !=index)
      } else {
        this.showMenu.push(index)
      }
    },
    toggleMobileSubMenuList(index){
      if(this.subMenu.includes(index)){
        this.subMenu = this.subMenu.filter(item => item !=index)
      } else {
        this.subMenu.push(index)
      }
    },
    toggleMobileMenu(){
      this.openMenu = !this.openMenu;
    },
    hold(index){
      this.selected = index
    },
    out(){
      this.selected = -1
    },
    checkWindow() {
      this.mobileType = window.outerWidth <= 900
      if(!this.mobileType){
        this.openMenu = false;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
  .mb-menu a{
    display:block;
  }
  .menu-item a{
    line-height: 50px;
    display: inline-block;
  }
  .logo img {
    position: absolute;
    z-index: 1000;
    top: 0;
    // width: 75px;
    // height: 75px;
  }
  a{
    color: unset !important;
    text-decoration: unset !important;
  }
  .back-shadow{
    // <!-- display: none; -->
    background-color: rgba(255,255,255,.0);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1;
    transition: background-color .5s;
  }
  .openmenu.back-shadow{
    display:block;
    background-color: rgba(19,20,21,.6);
  }
  body {
    --diy-color: #0261ff;
  }
  .md-login-shadow{
    width: 100%;
    height: 100px;
  }
  .hide .md-login{
    right: -100vw;
  }
  .md-login{
    color: #0261ff;
    font-weight: 700;
    font-size : 16px;
    height: 27px;
    padding-top: 16px;
    padding-bottom: 46px;
    border-top:1px rgb(231,233,237) solid;
    text-align: center;
    max-width:430px;
    width: 100%;
    position:fixed;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    transition: right .5s;
  }
  .mb-menu-sub-item-two{
    padding-left: 40px;
    overflow: hidden;
  }
  .mb-menu-sub-item-one{
    padding-left: 20px;
  }
  .open .open.mb-menu-sub-item-two>div,
  .open .mb-menu-sub-item .mb-menu-sub-item-one{
    max-height: 100px;
  }
  .mb-menu-sub-item-two>div,
  .mb-menu-sub-item .mb-menu-sub-item-one{
    max-height: 0;
    transition : display .5s, max-height .5s;
  }
  .mb-menu-sub-item-one,
  .mb-menu-sub-item{
    overflow: hidden;
  }
  .mb-menu-open-icon{
    font-size: 16px;
    float: right;
    font-weight:600;
    margin-top: 13px;
    color: #aaaaaa;
  }
  .mb-top-box{
    width: 100%;
    height: 53px;
  }
  .open.mb-menu-item .mb-menu-sub-item-one .mb-menu-open-icon{
    transform:rotate(0deg);
    color: #aaaaaa;
  }
  .open.mb-menu-item .open.mb-menu-sub-item-one .mb-menu-open-icon,
  .open.mb-menu-item .mb-menu-open-icon{
    color: #000000;
    transform:rotate(90deg);
  }
  .mb-menu-item {
    line-height: 48px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .mb-menu-open-icon {
    transition : color .5s, transform .5s;
  }
  .mb-menu.hide{
    right: -100vw;
  }
  .mb-menu{
    overflow: auto;
    top:0;
    right:0;
    max-width: 430px;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    z-index: 2;
    transition: right .5s;
  }
  .mb-menu-icon i{
    position: absolute;
    right: 30px;
    z-index: 4;
  }
  .mb-menu-list{
    background-color: #ffffff;
  }
  .mb-menu-icon{

    float: right;
    margin-top: 12px;
    font-size: 24px;
    color: #999999;
  }
  .mb-menu-icon.openmenu{
    color: #0261ff;
  }
  .mb-header{
    background-color: #fff;
    padding: 0 10px;
    margin-bottom: 10px;
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    z-index: 2;
    height: 53px;

  }

  .header {
    background-color: #fff;
    padding: 0 24px;
    margin-bottom: 0px;
    height: 100px;
    box-shadow: 0 0 50px rgb(0 0 0 / 5%);
  }
  .header-sub{
    display: inline-block;
  }
  .logo-item{
    height: 60px;
    margin-top: 23px;
    margin-left: 4px;
  }
  .right-sub{
    float: right;
    margin-top: 17px;
  }
  .header-item{
    margin-left: 20px;
    font-size: 12px;
    display: inline-block;
    cursor: pointer;
    line-height: 24px;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  .left-icon{
    width:185px;
    font-size: 18px;
    font-weight: 600;
    color: #777;
    font-style: oblique;
    letter-spacing: 2px;
    line-height: 50px;
    display: inline-block;
  }
  .menu{
    z-index: 10;
    position: absolute;
    background: #fff;
    left: 24px;
    right: 24px;
    top: 43px;
  }
  .menu-nav{
    padding: 0 16px;
    line-height: 57px;
    text-align: center;
    display: inline-block;
  }
  .menu-item{
    letter-spacing: 1px;
    position: relative;
    padding: 0 16px;
    display: inline-block;
    font-size: 14px;
    line-height: 50px;
    font-family: PingFang-SC-Regular,PingFang SC,Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif;
  }
  .simple-menu-item{
    letter-spacing: 1px;
    padding: 0 16px;
    display: inline-block;
    font-size: 14px;
    line-height: 0;
    overflow:hidden;
  }
  .menu-item:hover, header-item:hover {
    color: #0261ff;
    cursor: pointer;
  }
  .open-menu {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    font-size: 12px;
    font-weight: 600;
    display: none;
  }
  .menu-item:hover .open-menu{
    display: block;
  }
  .right-icon {
    float: right;
  }
  .go-login{
    height: 28px;
    min-width: 72px;
    padding: 0 12px;
    background-color: #0261ff;
    border: none;
    border-radius: 24px;
    line-height: 28px;
    color: #fff;
    font-size:14px;
    text-align:center;
    margin-top: 15px;
    cursor: pointer;
  }
  .go-login:hover{
    background-color:blue;
  }
  .menu-list{
    height: 0;
    overflow: hidden;
  }
  .menu-list-box{
    display:inline-block;
    width: fit-content;
    position: absolute;
    z-index:1;
    line-height: 20px;
    text-align: left;
    color: #000;
  }
  .menu-list-box-real{
    display: flex;
    position: relative;
    background: #fff;
    padding: 14px 12px 6px;
    border-bottom-left-radius:  8px;
    border-bottom-right-radius: 8px;
    /*width: 650px;*/
    box-shadow: 2px 6px 6px 3px #00000040;
  }
  .title-item{
    font-size: 16px;
    color: #0261ff;
    margin: 0 0 16px;
  }
  .value-item{
    font-size: 14px;
    margin: 0 0 16px;
    cursor: pointer;
  }
  .value-item:hover{
    color: #0261ff;
  }
  .info-item{
    font-size: 14px;
  }
  .menu-list-box-item{
    display: inline-block;
    max-width: 300px;
  }
  .menu-list-box-item.left-item{
    float: left;
    max-width: 350px;
    padding-right: 40px;
    margin-right: 8px;
    border-right: 1px solid #ccc;
  }

  .hidden{display:none;}
</style>
