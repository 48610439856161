<template>
  <!-- BOTTOM -->
  <div>
    <!-- :class="{ mobile: mobileType }" -->
    <div v-if="!mobileType" class="bottom">
      <div class="m">
        <ul>
          <li>
            <h2><a href="/cloud">云平台</a></h2>
            <ol>
              <li><a href="/cloud#syno">平台简介</a></li>
              <li><a href="/cloud#advantage">平台优势</a></li>
              <li><a href="/cloud#cmposition">平台组成</a></li>
              <li><a href="/cloud#industry">应用行业</a></li>
            </ol>
          </li>
          <li>
            <h2><a href="/hardware">硬件接入</a></h2>
            <ol>
              <li><a href="/hardware#explain">接入说明</a></li>
              <li><a href="/hardware#function">核心功能</a></li>
              <li><a href="/hardware#setp">开发步骤</a></li>
            </ol>
          </li>
          <li>
            <h2><a href="/app">APP开发</a></h2>
            <ol>
              <li><a href="/app#app">APP简介</a></li>
              <li><a href="/app#function">核心功能</a></li>
              <li><a href="/app#sdk">SDK下载</a></li>
            </ol>
          </li>
          <li>
            <h2><a href="/service">服务部署</a></h2>
            <ol>
              <li><a href="/service#synopsis">部署简介</a></li>
              <li><a href="/service#advantage">服务优势</a></li>
            </ol>
          </li>
          <li>
            <h2><a href="/case">方案&amp;案例</a></h2>
            <ol>
              <li><a href="/case#synopsis">方案简介</a></li>
              <li><a href="/case#case">成功案例</a></li>
            </ol>
          </li>
          <li>
            <h2><a href="/introduce">关于我们</a></h2>
          </li>
        </ul>
        <div class="contact">
          <h2>广州市科缔智能科技有限公司</h2>
          <h3>地址：广州市天河区天园街道金鹏大夏203</h3>
          <!-- <div class="contactImg"></div> -->
        </div>
      </div>
    </div>
    <div class="copyright">
      {{mobileType ?"" : codeString}}&nbsp;&nbsp;&nbsp;广州市科缔智能科技有限公司
      &nbsp;&nbsp;&nbsp;<a @click="goGov" :style="{'cursor':'pointer'}">经营许可证：粤ICP备19147545号</a>
      <!-- <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备19147545号&nbsp; -->
        <!-- <script type="text/javascript">
          var cnzz_protocol = (("https:" == document.location.protocol) ? " https://" : " http://");
          document.write(unescape("%3Cspan id='cnzz_stat_icon_1274166820'%3E%3C/span%3E%3Cscript src='" + cnzz_protocol + "s22.cnzz.com/z_stat.php%3Fid%3D1274166820%26show%3Dpic' type='text/javascript'%3E%3C/script%3E"));
        </script> -->
        <!-- <span id="cnzz_stat_icon_1274166820">
          <a href="https://www.cnzz.com/stat/website.php?web_id=1274166820" target="_blank" title="站长统计">
            <img border="0" hspace="0" vspace="0" src="http://icon.cnzz.com/img/pic.gif">
          </a>
        </span> -->
        <!-- <script src=" http://s22.cnzz.com/z_stat.php?id=1274166820&amp;show=pic" type="text/javascript"></script> -->
        <!-- <script src="http://c.cnzz.com/core.php?web_id=1274166820&amp;show=pic&amp;t=z" charset="utf-8" type="text/javascript"></script> -->
      <!-- </a> -->
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      mobileType : false,
    }
  },
  mounted() {
    this.checkWindow();
    window.addEventListener('resize',this.checkWindow);
  },
  computed:{
    codeString(){
      
      return `Copyright@ ${new Date().getFullYear()}  All Right Reserved`
    }
  },
  methods : {
    checkWindow() {
      this.mobileType = window.outerWidth <= 1200
      if(!this.mobileType){
        this.openMenu = false;
      }
    },
    goGov(){
      window.location.href = "https://beian.miit.gov.cn/"
    }
  }
};
</script>
<style scoped>
  .mobile.bottom .m{
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mobile ul{
    /*flex-direction: column;*/
    width: 100%;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }
  .mobile ul>li{
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    padding-left: 20px;
    box-sizing: border-box;
    width: 50%;
  }
  .mobile .contact{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ul{
    display: flex;
  }
  .bottom .contact .contactImg {
      width: 243px;
      height: 136px;
      /*background: url() no-repeat 0 -204px;*/
  }
  .contact{
    width: 390px;
  }
  .bottom .contact h2, .bottom .contact h3{
      width: 389px;
  }
  .bottom .contact h2, .bottom .contact h3, .bottom .contact .contactImg {
      font-size: 16px;
      font-weight: normal;
      float: left;
  }
  .bottom .contact h3 {
      font-size: 12px;
      /*padding-top: 10px;*/
      padding-bottom: 30px;
      margin: 0;
  }
  .bottom ul li ol li{
    padding:  5px 0;
    font-size: 12px;
  }
  .bottom ul li h2{
    font-size: 16px;
    font-weight: normal;
    color: #ccc;
    margin: 0;
    padding-bottom: 15px;
  }
  ol{
    padding: 0;
  }
  ul,li{list-style:none;}
  .bottom{
    background: #595959;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 16px;
    box-shadow: inset 0px 3px 10px 0px #333333;
  }
  .bottom .m{
    display: flex;
    justify-content: center;
  }
  .bottom ul li{
    width: 126px;
  }
  .bottom ul a , .contact h2, .contact h3{
    color:  #fff;
    text-decoration: none;
  }
  .copyright a{
    color: #aaa;
    text-decoration: none;
  }
  .copyright{
    width: 100%;
    float: left;
    text-align: center;
    height: 35px;
    line-height: 35px;
    background: #f3f3f3;
    color: #aaa;
    font-size: 12px;
  }
</style>