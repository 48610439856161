<template>
  <div>
    <Menu></Menu>
    <div class="main-box ">
      <div class="earth-row">
        <div>
          <h1>服务部署</h1>
          <p>在亚洲、北美、欧洲、南美洲和非洲 均有部署数据中心，覆盖全球主流市场用户服务需求</p>
          <div style="height: 48px; "></div>
        </div>
        <div class="earth-row-img">
          <img src="/img/img/index.png">
        </div>
      </div>
    </div>
    <div class="introduce" id="synopsis">
      <div class="int-title-box">
        <h2 class="int-title">部署简介</h2>
        <!-- <p class="int-sub-title">
          深耕行业多年，我们已发展为全球领先的 IoT 音视频平台。专业为您提供完善的产品服务
        </p> -->
      </div>
      <div class="int-flex-box">
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">服务器选择</h3>
              <div class="int-flex-box-item-info">
                <p>
                  基础云服务商主要有使用阿里云、亚马逊云、腾讯云和华为云以及地方运营商服务器，根据平台底层构建的需要，混合各大云服务商的优势，保障科缔云服务的稳定可靠
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">快速部署</h3>
              <div class="int-flex-box-item-info">
                <p>
                  具备快速构建接入节点能力，根据市场扩展需要，1-2个工作日内可完成整套设备接入服务器部署
                </p>
              </div>
            </div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item no-move no-border full-box">
          <div>
            <div class="int-flex-box-half img">
              <img src="/img/img/233796532506447872.png">
            </div>
            <div class="int-flex-box-half">
              <h3 class="int-flex-box-item-title">专属云服务</h3>
              <div class="int-flex-box-item-info">
                <p>
                  提供私有云搭建服务。根据客户需求提供服务器给我们部署，构建自己的云平台。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduce" id="advantage">
      <div class="int-title-box" id="advantage">
        <h2 class="int-title">服务优势</h2>
      </div>

      <div class="int-flex-box advantage">
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">服务稳定</h3>
              <p class="int-flex-box-item-info">经过多年运营，已形成体系和规范，目前服务已相当稳定，可以运行多年不出问题</p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">灵活部署</h3>
              <p class="int-flex-box-item-info">各功能模块解耦，可对不同需求进行针对性部署，需要什么功能就部署什么功能，灵活多变</p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">自我修复</h3>
              <h3 class="int-flex-box-item-info">自主研发针对性运维模块，出现问题能自动恢复，确保业务正常运作</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">集群部署</h3>
              <h3 class="int-flex-box-item-info">全球大小集群既独立又互相关联，保障全球用户随时随地享受同样的体验效果</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">高可用</h3>
              <h3 class="int-flex-box-item-info">服务器针对性配置高可用，服务器出现问题能自动切换，确保各服务正常通讯</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">负载均衡</h3>
              <h3 class="int-flex-box-item-info">采用独有算法，根据业务需求配置，使服务器集群间达到负载均衡，保障业务稳定</h3>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Menu from "@/pages/Dashboard/Menu.vue";
import Foot from "@/pages/Dashboard/Foot.vue";
export default {
  components:{
    Menu : Menu,
    Foot : Foot,
  },
  data() {
    return {
      selected: 0,
    };
  },
  mounted() {
  },
  methods : {
  }
};
</script>
<style scoped>
  div.introduce{
    margin: 72px auto;
    /*margin: 72px 0;*/
    padding: 0 72px;
    max-width: 1200px;
  }
  .advantage .int-flex-box-img{
    max-width: 200px;
    min-width: 200px;
  }
  .advantage .int-flex-box-img img {
    width: 200px;
  }
  .info-item-box p{
    margin: 0 0 20px 0;
  }
  .int-flex-box-list-item{
    align-items: stretch !important;
    flex-direction: row !important;
  }
  .info-item-box img{
    margin: 42px 0;
  }
  .info-item-box{
    box-sizing: border-box;
    padding: 58px 36px 48px;
    background-color: #ffff;
    flex-basis: 60%;
    flex-grow: 1;
    flex-shrink: 0;
    /*max-width: ;*/
  }
  .list-item-box{
    box-sizing: border-box;
    padding: 58px 0;
    background-color: #f6fafe;
    flex-basis: 40%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 360px;
    min-height: 600px;
  }
  .list-item-box .list-item-list {
    cursor: pointer;
    padding: 0 54px;
    line-height: 48px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  .list-item-box .list-item-list.selected {
    background: #025dff;
    color: #ffffff;
  }
  .int-flex-box-half .int-flex-box-item-title{
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 36px;
    height: unset;
  }
  .int-flex-box-half.img{
    text-align: center;
  }
  img{
    max-width: 100%;
  }
  .int-flex-box-half{
    box-sizing: border-box;
    padding: 0 36px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .int-flex-box-img img{
    width: 300px;
  }
  .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .int-flex-box-item-title{
    font-size: 20px;
    /*height: 30px;*/
    margin: 0;
    margin-bottom: 12px;
  }
  .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 54px 16px 54px 40px;
    flex-direction: column;
  }
  .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .int-flex-box-item.no-move>div:hover{
    top: 0;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
  }
  .int-flex-box-item.no-border>div{
    border: 0 !important;
    box-shadow: none !important;
  }
  .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    height: 100%;
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
    justify-content: center;
    align-items: center;
  }
  .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .int-flex-box{
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }
  .int-title-box{
    text-align: center;
    padding: 0 12px 60px;
  }
  .int-sub-title{
    margin-top:24px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-bottom: 0;
  }
  .int-title{
    line-height: 50px;
    height: 50px;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
  }
  .earth-row{
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    height: 500px;
  }
  .earth-row img{
    width: 100%;
  }
  .earth-row p{
    color: #606266;
    line-height: 26px;
  }

  .earth-row>div{
    align-items:center;
    padding: 0 12px;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .main-box p{
    margin: 32px 0 42px;
    font-size: 16px;
  }
  .main-box h1{
    line-height: 66px;
    font-size: 56px;
    margin: 0;
    /*margin-top: 64px;*/
  }
  .main-box{
    box-shadow: inset 0 0 50px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    height: 500px;
    width: 100%;
    /*padding: 0 72px;*/
    margin: 0 auto;
    background-color: #eef2f6;
    clear: both;
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  @media screen and (max-width: 1023px){
    div.introduce{
      padding: 0 32px;
    }
    .int-flex-box-half {
        box-sizing: border-box;
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .info-item-box{
      padding: 12px 36px;
    }
    .list-item-box{
      padding: 12px 0;
    }
    .init-title-box{
      height: 150px;
    }
    .main-box p {
      margin: 24px 0;
    }
    .main-box {
      padding: 0 12px;
      height: unset;
    }
    .earth-row{
      height: unset;
    }
    .earth-row>div{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 80px 0 36px;
    }
    .earth-row-img{
      display: none;
    }
    .main-box h1{
      font-size: 36px;
    }
    .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
    .int-flex-box-span {
      padding: 48px 26px 0;
    }
  }
</style>