export default { 
  zh :{
    net_type : "网络类型",
    srv_id : "服务器ID",
    srv_ip : "服务器IP",
    srv_zone: "服务器区域",
    port : "端口",
    dev_state_0          : '离线',
    dev_state_1          : '在线',
    net_type_0           : '未知',        
    net_type_1           : '公网网络',    
    net_type_2           : '完全锥形NAT', 
    net_type_3           : 'IP受限NAT',   
    net_type_4           : '端口受限NAT', 
    net_type_5           : '对称性NAT',   
    search_by_ip: "根据IP查询",
    deivce_code: "设备标识",
    deivce_code_placehoder: "可选，未填将由服务器生成",
    template_name       : "模板名称",
    themes              : "问题主题",
    problem_description : "问题描述",
    contact             : "联系方式",
    submit              : "提交",
    feedback_from_1     : "请详细的描述问题情况已便我们提供更好的帮助",
    feedback_from_2     : "选填，便于我们与你联系",
    feedback_time       : "反馈时间",
    have_feed           : "已反馈",
    process             : "处理中",
    resolved            : "已解决",
    viewinf_commun_record : "查看反馈",
    submit_feedback       : "提交反馈",
    problem_feedback      : "问题反馈",
    details_feedback      : "反馈详情",

    gateway_info: "多通道型产品可支持对下挂子设备通道进行管理。",
    role_setting : "角色配置",
    need_xlsx: "上传的必须是xlsx格式文件",
    source_area_code : "源区域编码",
    object_area_code : "目标区域编码",
    file_error : "文件错误",
    unselect : "无体系",
    the_code : "编码",
    source_area_type : "源类型",
    object_area_type : "目标类型",
    continent :"大洲",
    country   :"国家",
    SC : "塞舌尔",
    Seychelles : "塞舌尔",
    low : "低",
    center : "中",
    high: "高",
    product_list : "产品列表",
    fir_update : "固件升级",
    level : "等级",
    node_type : "节点类型",
    net_video : "网络硬盘摄像机",
    video : "摄像机",
    device : "单通道",
    gateway : "多通道",
    all_ : "全",
    asia : "亚洲",
    europe : "欧洲",
    africa : "非洲",
    latin_america : "拉丁美洲",
    north_america : "北美洲",
    oceania : "大洋洲",
    antarctica : "南极洲",
    push_record : "推送记录",
    payment_platform : "支付平台",
    cloud_storage : "云存储平台",
    lang_i18n : "zh",
    icon : "图标",
    add_attr : "添加属性",
    edit_attr : "修改属性",
    del_attr : "删除属性",
    input_in_this : "在此输入后按回车添加",
    stock_type_mng : "商品分类管理",
    push_setting : "推送配置",
    MT : "马耳他",
    document : "文档",
    modify_comp : "修改公司",
    cloud_server : "云服务器",
    cloud_service : "云服务",
    // add_stock : "添加库存",
    "United Kingdom" : "英国",
    func_conf : "功能配置",
    user_statis: "用户统计",
    edit_stock : "修改库存",
      dev_load : "设备在线数",
      lic_load : "设备LIC数",
      check_delete : `您是否确定要删除 %1%?`,
      device_info : "设备详情",
      search_by_uid : "根据UID查询",
      search_by_mac : "根据MAC查询",
      click_select_file : "点击选择文件",
      master: "主",
      sub   : "从",
      order_info: "订单记录", 
      no_dev_unbind : "没有可以解绑的设备",
      unbind_success: "解绑成功",
      push_platform_set : "推送平台配置",
      push_template_set : "推送模板配置",
      tcp_addr  : "TCP地址",
      link_addr : "连接地址",
      stock : "库存",
      selected : "已选择",
      edit_app_set : "修改APP配置",
      tips : "提示",
    // 公用翻译部分
    // error code ajax回调内容 data.h.error
      home_page            : "公司主页",
      umeye : "UM智能平台",
      unknown_error : "未知错误",
      umeye_platform       : "OPEN 平台",
      http_error_513       : "您的账号没有权限",
      http_error_512       : "密码错误",
      http_error_514       : "您输入的账号或密码错误，请重试",
      http_error_514_2     : "您输入的密码错误",
      http_error_515       : "该账号ID不唯一",
      http_error_516       : "该账号缺少关联企业信息",
      http_error_517       : "该账号已被禁用",
      http_error_518       : "该账号已过期",
      http_error_519       : "未知状态类型",
      http_error_409       : "该ID已被添加",
      http_error_500       : "服务器问题",
      http_error_409_2     : "该用户名已存在",
      e_515_room_has_owner : "该房间已有业主",
      login_error          : "登录失败",
      login_success        : "登陆成功",
      email_error          : "邮箱格式错误",
      phone_error          : "电话格式错误",
      map_error            : "地点查询失败",
      pls_input_post_num   : "请输入大于零的数字",
      pay_success          : "恭喜您, 支付成功",
      num_0_01_to_5000     : "充值范围为0.01元到5000元",
      shop_server          : "OPEN云平台",
      unknow_state         : "未知类型",
      now_loading          : "正在确认请稍等...",
      // logout               : "登出",
      payment_component    : "支付服务",
      upload_success       : "上传成功",
      invalid_string       : "无效字符串",
      invalid              : "请求无效",
      userid_email_phone_is_used : "用户ID或邮箱或电话已被占用,请输入未使用的邮箱和电话",
      
      // logout_success       : "登出成功",
      pls_sel_right_node   : "请选择可修改的节点",
      control_mng          : "控制台管理",
      product_server       : " 产品与服务",
      menu_url             : "菜单URL",
      pls_input_menu_url   : "请输入菜单URL(可选)",
      // 登录页面
        account              : "账号",
        password             : "密码",
        pls_input_password   : "请输入密码",
        verificationCode     : "验证码",
        signin_msg_id1       : "无效的验证码，请重新输入或刷新页面！",
        login                : "登录",

      // 首页
        smart_community_server : "智能社区服务",
        userInfo             : "用户信息",
        reset_passwd         : "修改密码",
        system_prompt        : "系统提示",
        exitTip              : "您确定要退出吗？",
        cancle               : "取消",
        sure                 : "确定",
        exit                 : "退出",
        index                : "首页",
        start                : "开始",
        end                  : "结束",
        add                  : "添加",
        search               : "查询",
        hide                 : "隐藏",
        reset                : "重置",
        operate              : "操作",
        setting              : "配置",
        edit                 : "编辑",
        delete               : "删除",
        batch_delete         : "批量删除",
        pls_select           : "请选择",
        none_data            : "暂无数据",
        total                : "总计",
        prev_page            : "上一页",
        next_page            : "下一页",
        name                 : "名称",
        en_name              : "英文名称",
        state                : "状态",
        normal               : "正常",
        open                 : "开启",
        close                : "不开启",
        disable              : "禁用",
        overdue              : "过期",
        is_sure_edit         : "您是否确定修改?",
        is_sure_del          : "是否确定要删除",
        is_sure_del_has_node : "该节点下有子节点，是否确定要删除",
        created_thing_time   : "创建时间",
        select_time_range    : "选择时间范围",
        time_range           : "时间范围",
        create_success       : "创建成功",
        add_success          : "添加成功",
        add_success_2        : "添加成功,你可以留在这个页面继续添加,或点击'取消'关闭页面",
        edit_success         : "修改成功",
        delete_success       : "删除成功",
        setup_success        : "生成成功",
        add_fail             : "添加失败",
        edit_fail            : "修改失败",
        delete_fail          : "删除失败",
        setup_fail           : "生成失败",
        get_error            : "获取信息失败",
        search_type          : "查询方式",
        all_select           : "全选",
        pls_input             : "请输入",
        pls_check_verify : "请确认必填项是否填写完整",
        pls_check_verify_2 : "请确认填写是否符合规范",
        pls_input_num_id : "请输入数字ID",
        pls_input_num : "请输入数字",
        pls_input_phone : "请输入正确格式的电话号码",
        pls_input_phone_11 : "请输入11位手机号码",
        pls_input_email: "请输入正确格式的电子邮箱",
        pls_input_id_card : "请输入正确格式的身份证号",
        china : "中国",
        now_here : "所在地",
        is_loading : "正在加载",
        to : "到",
        start_end_time : "起止时间",
        pls_select_state : "请选择状态",
        show_all : "展开全部",
        show : "展开",
        only_show_level_1 : "仅显示第一级菜单",
        upload : "上传",
        upload_fail : "上传失败",
        upload_error_type : "上传的文件格式错误",
        more   : "更多",
        // 1级菜单
        company_manage : "公司管理 ",
        estate_manage : "物业管理",
        advertise_manage : "广告管理",
        device_manage : "设备管理",
        data_analyse : "数据分析",

        // 2级菜单
        user_manage : "客户管理",
        security_setting : "安全设置",
        system_setting : "系统设置",
        community_manage : "社区管理",
        security_manage : "安防管理",
        statistics_manage : "统计管理",
        list_setting : "菜单配置",
        community_advertise : "小区广告",
        register_manage : "注册管理",

        // 3级菜单
        business_manage : "企业管理",
        personal_info : "个人信息",
        personal_info_mini : "个",
        company_info : "公司信息",
        modify_pw : "修改密码",
        modify_info : "修改菜单",
        role_manage : "角色管理",
        owner_manage : "业主管理",
        payment_manage : "缴费管理",
        notice_manage : "公告管理",
        call_manage : "屏锁呼叫管理",
        communicate_carousel : "小区轮播",
        periphery_server : "周边服务",
        product_manage : "产品管理",
        data_general : "数据分析",
        area_spread : "区域分布",
        user_type : "角色类型",
        user : "用户",

        // 公司管理
        confirm_password : "确认密码",
        pls_input_confirm_password : "请再次输入密码",

        // 客户管理
        add_company : "添加企业",
        edit_company : "修改企业",
        belong_company : "所属企业",
        pls_select_belong_company : "请选择所属企业",
        login_name : "用户昵称",
        pls_input_login_name : "请输入用户昵称",
        phone : "联系电话",
        // indiv_operat : "个人自营",
        // company : "企业",
        company_type : "企业类型",
        pls_select_company_type : "请选择企业类型",
        created_time : "注册时间",
        user_name : "用户姓名",
        // pls_input_user_name : "请输入用户姓名",
        user_id : "用户ID",
        pls_input_user_name : "请输入用户ID",
        account_state : "账号状态",
        // email : "电子邮箱",
        user_role : "用户角色",
        pls_select_user_role : "请选择用户角色",
        remarks : "备注",
        pls_input_remarks : "请输入备注",
        add_user : "添加用户",
        edit_user : "编辑用户",
        company_name : "企业名称",
        pls_input_company_name : "请输入企业名称",
        agent_type : "代理商类型",
        pls_sel_agent_type : "请选择代理商类型",
        map_position : "地图位置",
        company_address : "公司地址",
        pls_input_company_address : "请输入公司地址",
        leading_name : "  联系人",
        pls_input_leading_name : "请输入联系人名称",
        sort_num : "排序号",
        pls_input_sort_num : "请输入数字排序号，不输入则默认放置于该登记末尾",
        pls_input_sort_num_2 : "请输入数字排序号",
        super_admin : "超级管理员",
        admin : "管理员",
        factory : "厂商",
        ex_agents : "总代",
        agents : "代理商类型",
        trader : "分销商",
        property : "物业",
        user_only_company : "仅该公司下的用户",
        user_all_company : "该公司及下属公司的用户",
        validity_time : "有效期限",
        pls_input_validity_time : "请确定期限日期",
        date : "日期",
        // add_user_com : "添加企业用户",
        // add_user_self : "添加个人用户",
        company_id : "企业ID",
        too_long_4 : "最多只能4位",
        developer : "开发者",
        cust_comp : "客户企业",
        sub_cust_comp : "客户下级营销企业",

        // 安全设置
        ip_address : "登录IP",
        update_date : "更新时间",
        old_password : "旧密码",
        pls_input_old_password : "请输入旧密码",
        new_password : "新密码",
        pls_input_new_password : "请输入新密码",
        password_no_equal : "两次密码输入不相同",

        // 角色管理
        add_role : "添加角色",
        edit_role : "修改角色",
        del_role : "删除角色",
        parent_node : "上级节点",
        none_parent_node : "无上级节点",
        pls_input_role_name : "请输入角色名称",
        pls_input_role_id : "请输入角色ID",
        // en_name : "英文名称",
        pls_input_en_name : "请输入英文名称",
        role_form_tips1 : "该菜单或操作是否显示到系统菜单中",
        role_list : "角色菜单",

        // 菜单管理
        pls_sel_node : "请选择节点",
        is_last_child : "该菜单不能继续添加子菜单",

        // 资产管理
        application_time : "申请时间",
        now_withdrawal : "立即提现",
        now_recharge : "立即充值",
        withdrawal_title : "提现(请准确输入提现信息，便于更快到账！)",
        cardholder : "持卡人名字",
        link_phone : "联系电话",
        pls_input_cardholder : "请输入持卡姓名",
        pls_input_link_phone : "请输入联系电话",
        pls_input_withdrawal_amount : "请输入提现金额",
        belong_bank : "所属银行",
        pls_input_belong_bank : "请输入所属银行",
        bank_card_num : "银行卡号",
        pls_input_bank_card_num : "请输入银行卡号",
        open_account_place : "开户地点",
        pls_input_open_account_place : "请输入开户地点",
        balance : "余额",
        your_balance : "您当前余额为",
        rmb : "元",
        account_expenses_type : "收支类型",
        account_expenses_money : "收支金额",
        operation_time : "操作时间",
        recharge_money : "钱包余额充值",
        recharge_num : "充值金额",
        pls_input_recharge_num : "请输入充值金额",
        recharge_type : "充值类型",
        wechart : "微信",
        close_layer : "关闭页面",
        income_expen_type : "收支类型",
        pls_select_income_expen_type : "请选择",
        amount_rmb : "金额(单位: 元)",
        withdrawal_amount : "提现金额",
        procedure_cost : "手续费用",
        all_total_cost : "实际费用",
        examine : "审核",
        details : "详情",
        payment : "收支",
        no_audited : "待审核",
        first_audit_pass : "初次审核通过",
        first_audit_no_pass : "初次审核未通过",
        audit_pass : "审核通过",
        audit_no_pass : "审核未通过",
        recharge : "充值",
        cash_deduction : "提现扣除",
        cash_withdrawal : "提现返还",
        alipay : "支付宝",
        paypal : "PayPal",
        platform_publicKey : "平台支付公钥",
        platform_privateKey : "平台支付私钥",
        platform_return_url : "同步回调地址",
        platform_notify_url : "异步回调地址",
        platform_appid : "appid配置项",
        config_ids : "配置ID",
        set_payment : "配置支付模式",

      //  app 管理
        client_type : "客户端类型",
        // app_id : "APP ID",
        app_name : "APP 名称",

      // 物业管理
        // 社区管理
        community_name : "小区名称",
        pls_input_community_name : "请输入小区名称",
        community_phone : "小区电话",
        pls_input_community_phone : "请输入小区电话",
        add_community : "添加小区",
        edit_community : "编辑小区",
        community_short_code : "小区短编号",
        belong_area : "所属区域",
        pls_input_belong_area : "请输入小区所属区域",
        estate_cost_setting : "物业费设置 ",
        // build_name : "大楼名称",
        // build_order : "大楼序列",

        add_build : "添加大楼",
        edit_build : "编辑大楼",
        batch_add : "批量添加",

        add_floor : "添加楼层",
        edit_floor : "编辑楼层",

        add_room : "增加房间",
        edit_room : "编辑房间",

        build_name : "大楼名称",
        pls_input_build_name : "请输入大楼名称",
        build_order : "大楼序列",
        pls_input_build_order_num : "大楼序列数字",

        floor_name : "楼层名称",
        pls_input_floor_name : "请输入楼层名称",
        floor_order : "楼层序列",
        pls_input_floor_order_num : "楼层序列数字",

        room_name : "房间名称",
        pls_input_room_name : "请输入房间名称",
        room_order : "房间序列",
        pls_input_room_order_num : "房间序列数字",

        room_size : "房间大小",
        is_using : "是否启用",
        floor_count : "大楼层数",
        pls_input_floor_count : "请输入需要添加的楼层数",
        room_count : "每层间数",
        pls_input_room_count : "请输入需要添加的每层的房间数",
        room_square : "房间面积",
        pls_input_room_square : "请输入房间面积",
        room_is_use : "房间状态",
        is_use : "启用",
        is_not_use : "未启用",

        pay_state : "收款方式",
        no_open_pay : "不开启收款",
        // platform : "平台收款",
        pay_code : "收款码收款",
        fee_square : "每平方物业费",
        pls_input_fee_square : "请输入每平方物业费",
        fee_other : "其他费用",
        pls_input_fee_other : "请输入其他费用",
        fee_door : "不交费禁用门禁开门",
        form_info_payment_1 : "每月1号后，开启或者关闭物业缴费，次月1日生效。",
        form_info_payment_2 : "例如3月2日开启物业缴费，4月1日生效。",
        overdue_day : "物业费逾期天数",
        pls_input_overdue_day_num : "请输入物业费逾期天数",
        pay_code_2 : "支付宝/微信收款码",
        upload_img : "上传图片",
        prop : "业主",
        prop_name : "业主名称",
        pls_input_prop_name : "请输入业主名称",
        prop_type : "业主类型",
        prop_state : "业主状态",
        prop_phone : "业主电话",
        prop_room : "小区户号",
        add_prop : "添加业主",
        put_in_prop : "导入业主",
        put_out_prop : "导出业主",
        edit_prop : "修改业主",
        prop_sheets : "业主表格",
        pls_sel_build : "请选择楼栋",
        pls_sel_floor : "请选择楼层",
        sel_room : "选择房间",
        room : "房间",
        pls_sel_room : "请选择房间",
        del_room : "删除持有房间",

        owner_phone : "业主电话",
        pls_input_owner_phone : "请输入业主电话",


        // 公告管理
        notice_title : "公告标题",
        pls_input_notice_title : "请输入公告标题",
        belong_community : "所属小区",
        notice_content : "公告内容",
        pls_input_notice_content : "请输入公告内容",

        start_time : "开始时间",
        sel_time : "选择时间",
        add_notice : "添加公告",
        edit_notice : "修改公告",
        pls_sel_community : "请选择小区",
        id_card_number : "身份证号",
        pls_input_id_card_number : "请输入身份证号",

        // 缴费管理
        is_pay : "是否缴纳",
        pay : "已缴纳",
        no_pay : "未缴纳",
        fee_value : "缴纳费用",
        fee_type : "费用类型",
        mater_fee : "物管费用",
        other_fee : "其他费用",
        prop_position : "区域范围",
        do_create : "是否创建",
        create_material_cost : "生成每月物管费用",
            
      // 菜单配置
        main_menu : "主菜单",
        sub_menu : "子菜单",
        add_main_menu : "添加第一级菜单",
        add_sub_menu : "给选中菜单添加子菜单",
        edit_menu : "修改选中菜单",
        delete_menu : "删除选中菜单",
        menu_id : "ID",
        pls_input_menu_id : "请输入菜单的数字ID",
        menu_key : "关联Key",
        pls_input_menu_key : "请输入菜单的KEY",
        menu_name : "名称",
        pls_input_menu_name : "请输入菜单名称",
        role_id : "角色ID",
        role_name : "角色名称",
        role_type : "角色类型",
        system_role : "系统角色",
        defined_role : "自定义角色",
        role_state : "角色状态",
        select_face : "选择人脸",
        platform_appKey : "平台KEY",
        platform_appSecret : "平台Secret",
        platform_endPoint : "地域节点",
        platform_port : "访问端口",
        platform_bucket : "存储节点",
        platform_file_prefix : "文件前缀",
        platform_store_path : "存储路径",
        platform_id : "平台ID",
        platform_type_name : "平台类型名称",
        // belong_area : "所属区域",
        pls_input_platform_appKey : "请输入平台KEY",
        pls_input_platform_appSecret : "请输入平台Secret",
        pls_input_platform_endPoint : "请输入地域节点",
        pls_input_platform_port : "请输入访问端口",
        pls_input_platform_bucket : "请输入存储节点",
        pls_input_platform_file_prefix : "请输入文件前缀",
        pls_input_platform_store_path : "请输入存储路径",
        pls_select_platform_type : "请选择云存储类型",
        // platform_type : "云存储类型",
        shopping_mng : "云存储管理",
        check_logout : "是否退出登录？",
        logout : "退出登录",
        // more_info : "更多信息",
        
        menu_type : "菜单类型",
        server_type : "服务类型",
        pls_input_server_type : "请输入服务类型",
        server_key : "服务key",
        pls_input_server_key : "请输入服务key",
        server_name : "服务名称",
        pls_input_server_name : "请输入服务名称",
        // add_server : "添加服务",
        // edit_server : "修改服务",
        // platform_order_id : "订单ID",
        add_user_com : "添加企业用户",
        add_user_self : "添加个人用户",
        // user_type : "角色类型",
        indiv_operat : "个人自营",
        company : "企业",

    // 广告运营
      // 小区广告管理
      server_title : "服务标题",
      advert_title : "广告标题",
      add_server : "添加服务",
      edit_server : "修改服务",
      add_advert : "添加广告",
      edit_advert : "修改广告",
      search_for_title : "根据标题查找",
      title : "标题",
      pls_input_title : "请输入标题",
      content : "内容",
      pls_input_content : "请输入内容",
      url : "URL",
      pls_input_url : "请输入URL",
      address : "地址",
      pls_input_address : "请输入地址",
      img : "图片",
      is_recommend : "是否推荐",
      recommend : "推荐",
      not_recommend : "不推荐",

    // 设备管理
      // 注册管理
      add_productType : "添加设备类型",
      edit_productType : "修改设备类型",
      type_id : "类型ID",
      type_name : "类型名称",
      pls_input_type_name : "请输入类型名称",
      product_name : "产品名称",
      pls_input_product_name : "请输入产品名称",
      pls_sel_product : "请选择产品",
      product_code : "产品编号",
      pls_input_product_code : "请输入产品编号",
      product_type : "产品类型",
      pls_sel_product_type : "请选择产品类型",
      add_poduct : "添加产品",
      edit_poduct : "修改产品",
      product_content : "产品描述",
      pls_input_product_content : "请输入产品描述",
      product_tag : "设备标签",
      pls_input_product_tag : "请输入设备标签",
      review_state : "审核状态",
      not_review_state : "未审核",
      via : "通过",
      is_or_not_via : "是否通过",
      refuse : "拒绝",
      not_via : "未通过",
      put_in_dev : "导入设备",
      frame : "模板",
      import_course : "导入教程",
      import_and_update : "导入",
      dev_code : "设备编号",
      pls_input_dev_code : "请输入设备编号",
      active_time : "激活时间",
      dev_tag : "设备标签",
      pls_select_product : "请先选择产品",
      dev_name : "设备名称",
      pls_input_dev_name : "请输入设备名称",
      dev_type : "设备类型",
      dev_area : "设备区域 ",
      dev_snid : "设备ID",
      pls_input_dev_snid : "请输入设备ID",
      dev_pwd : "设备密码",
      pls_input_dev_pwd : "请输入设备密码",
      // link_id : "安装位置",
      add_dev : "添加设备",
      edit_dev : "修改设备",
      product_id : "产品ID",

    // 短信推送
      type : "类型",
      id : "ID",
      area_type : "区域类型",
      param_type : "参数类型",
      param_content : "参数内容",
      param : "参数",
      platform : "短信平台",
      // key_id : "平台KeyId",
      pls_input_key_id : "请输入平台KeyId",
      // key_secret : "平台Key密码",
      pls_input_key_secret : "请输入平台Key密码",
      // sign_name : "短信签名",
      pls_input_sign_name : "请输入短信签名",
      template_code : "短信模板编码",
      has_code : " 是否有验证码",

      domestic_info : "国内信息",
      internat_info : "港澳台/国际信息",
      custom_logo : "客户端定制标识",
      server_ip : "服务器IP",
      continent_code : "大洲简称",
      aliyun : "阿里云",
      reg_notice : "注册通知",
      adv_notice : "激活通知 ",
      all : "全部",
      unlimit : "无限制",
      search_password : "找回密码",
      server_victoria : "服务器运维",
      alarm_notice : "报警通知",

      is_has_code : "存在",
      no_has_code : "不存在",
      template_lang : "模板语言",
      pls_input_template_lang : "请输入模板类型",
      pls_select_template_lang : "请选择模板语言",
      template_body : "模板内容",
      template_param : "模板参数",
      pls_input_template_body : "请输入模板内容",
      pls_input_template_param : "请输入模板参数",

      pls_input_param : "请输入参数",
      add_sms : "添加短信",

    // 数据统计
      week : "周",
      month : "月",
      total_property : "总资产(元)",
      total_pay : "总支出",
      total_income : "总收益",
      user_count : "用户数",
      partner : "合作伙伴",
      app_user : "APP用户",
      dev_count : "设备数",
      activation_rate : "激活率",
      house_count : "房源数",
      advertise_count : "广告交易数",
      put_in_advertise_count : "投放广告数量",

      was_pay : "支出",
      was_income : "收益",
      data_sum : "数据汇总",
      unit_yuan : "(元)",

    // 商品页面
      add_class : "添加分类",
      sub_class : "子分类",
      add_sub_class : " 添加子分类",
      sort : "序列",
      class_name : "分类名称",
      pls_input_class_name : "请输入分类名称",
      edit_class : "修改分类",
      attr_name : "属性名",
      attr : "属性",
      attr_code : "属性code",
      attr_value : "属性值",

      good_name : "商品名",
      add_good : "添加商品",
      edit_good : "修改商品",
      good_stock : "商品库存",
      stock_num : "库存数",
      sell_num : "销售数量",
      add_stock : "添加库存",
      comment_num : "评论数",
      seller_id : "卖家ID",
      stock_id : "库存ID",
      good_sub_name : "库存名",
      stock_state : "库存状态",
      orginal_fee : "原始价格",
      fact_fee  : "实际价格",
      limit_stock_num : "库存限制",
      lower_shelf : "下架",
      upper_shelf : "上架",
      good_attr : "商品属性",
      stock_attr : "库存属性",
      have_limit : "有限制",
      cant_empty : "不能为空",
      illegal_param : "参数不正确",

      pay_type : "支付平台",
      // alipay : "支付宝",
      order_id : "商品订单号",
      buy_id : "购买人",
      buy_list_id : "支付订单号",
      app_id : "APP ID",
      pay_time : "支付时间",
      price : "单价",
      count : "数量",
      total_price : "总价",
      more_info : "更多信息",
      vaildity_day_num : "有效时长具体天数",
      validity_day : "有效时长",
      undo : "未支付",
      payed : "已支付",
      success : "支付成功",
      fail : "支付失败",
      has_cancle : "已撤销",
      revoke : "订单失效",
      save_time : "保存时间",
      u_id : "设备ID",
      platform_order_id : "商品订单号",
      // app_name : "APP名称",
      day: " 天",
      pay_order_id : "支付订单号",
      buy_user_id : "购买用户ID",
      umid : "设备ID",
      logout_success : "登出成功",
      do_order_export : "是否查询并导出以下条件的所有订单",
      export_xls : "导出xls",
      import_help : "导入教程",
      order_state : "订单状态",

    // APP 
      // client_type : "客户端类型",
      // app_id : "APP ID",
      // app_name : "APP 名称",
      // app_packet : "包名",
      edit_app_config : "修改APP设定",
      limit_get_produce : "商品限制",
      product : "商品",

    // 设备管理
      mapping_state : "匹配状态",
      flie_dev : "设备信息",
      flie_dev_type : "设备信息类型",
      ali_three_dev : "阿里三元组设备信息",
      not_mapping : "未配对",
      is_mapping : "已配对",
      import_time : "导入时间",
      show_comp_dev_xls_info : "查看导入企业设备信息",
      import_state : "状态",
      wait_sync_database : "待导入",
      is_sync_database : "正在导入",
      has_sync_database : "已导入",
      import_id : "ID",
      import_file : "文件",
      import_comp_id : "导入企业id",
      mng_num : "总数",
      succ_num : "成功",
      fail_num : "失败",
      fail_file_url : "导入失败数据",
      // update_time : "最后修改时间",
      update_time : "完成时间",
      is_none : "暂无",
      dev_pk : "三元组pk值",
      dev_dn : "三元组dn值",
      rele_comp_id : "设备关联企业id",
        
    add_app : "添加APP",
    change_app : "修改绑定APP",
    edit_app : "修改APP",
    app_packet : "包名",
    create_time : "创建时间",
    unknow : "未知",
    web : "web",
    android : "Android",
    ios : "IOS",
    mac : "MAC",
    ipad : "ipad",
    pc : "pc",
    app_ids : "绑定APP",

    edit_control_panel_role : "修改控制台",
    edit_shop_page_role : "修改商店",
    edit_cloud_role : "修改云存储",
    edit_payment_role : "修改支付平台",
    set_user_role : "设置用户角色",

    // developer : "开发者",
    // cust_comp : "客户企业",
    // sub_cust_comp : "客户下级营销企业",
    // too_long_4 : "最多只能4位",
    setting_payment : "支付配置",
    setting_cloud : "云存配置",
    setting_product : "商品配置",


    link_visual : "LinkVisual视频云存储",
    ali_oss : "阿里云oss",
    add_cloud : "添加云存储",
    edit_cloud : "修改云存储",
    sales_num : "销售数量",
    payment_name : "名称",

    platform_name : "平台名称",
    pls_input_platform_name : "请输入平台名称",
    zone : "区域 ",
    pls_input_zone : "请输入区域",
    env : "环境信息",
    // http_addr : "HTTP地址",
    region : "所在区域",
    sys : "体系",
    version : "版本",
    inline : "在线",
    offline : "离线",
    umgreg : "GReg",
    umguser : "GUser",
    umgopen : "GAPI",
    umgmall : "GMall",
    umgpay : "GPay",
    umposs : "GOSS",
    umiot : "GIOT",
    umgpush : "GPush",
    umgda : " GDA",
    ota_server : "GOTA",

    aws : "亚马逊AWS",
    status : "状态",
    belong_co : "所属大洲",
    belong_areas : "所属区域",
    prod : "正式",
    test : "测试",
    comp_key : "公司KEY",
    effect_month : "有效月份时长",
    orginal_usd_fee : " 原价(美元)",
    fact_usd_fee : "实际价格(美元)",
    pls_input_post_num_or_zero : "请输入大于等于0的数字",
    dev_uid : "设备UID",
    has_error_row : "有错误",
    env_type : "环境类型",
    build_env : "实际环境",
    test_env : "测试环境",

    only_sale_one : "限量销售",
    not_sale_only : "无限制",
    sale_only : "只限买1",
    auth_server : "注册服务",
    hostname : "host名字",
    other_host_name : "别名",
    unauth : "未授权",
    ver_code : "版本号",
    force_update : "更新方式",
    force : "强制",
    force_update_select : "更新方式",
    user_select : "用户选择",
    submit_change : "提交更改",
    add_detail_info : "添加新的详细信息",
    oat_title : "标题",
    oat_lang : "语言",
    oat_url : "URL",
    oat_content : "说明",
    pls_input_app_id : "请输入APP ID",
    pls_input_ver_code : "请输入版本号",
    pls_input_ver_code_num : "版本号请输入合法数字",

    other_lang   : "其他语言",
    en      : "英文",
    "zh-CN"   : "简体中文",
    "zh-TW"   : "繁体中文",
    language : "语言",
    code : "编码",
    tmp_type_1 : "重置密码",
    tmp_type_2 : "注册通知",
    tmp_type_3 : "激活通知",
    tmp_type_4 : "服务器运维",
    tmp_type_5 : "注册验证码",
    tmp_type_6 : "修改密码验证码",
    tmp_type_7 : "报警通知",
    tmp_type_8 : "系统通知",
    tmp_type_9 : "设备超级密码通知",
    sms : "短信",
    email : "邮件",
    msg : "消息",
    template_type : "模板类型",

    add_sms_temp : "添加短信模板",
    add_email_temp : "添加邮件模板",
    add_msg_temp : "添加信息模板",
    edit_sms_temp : "修改短信模板",
    edit_email_temp : "修改邮件模板",
    edit_msg_temp : "修改信息模板",
    add_sms_plat : "添加短信平台",
    add_email_plat : "添加邮件平台",
    add_msg_plat : "添加消息平台",
    edit_sms_plat : "修改短信平台",
    edit_email_plat : "修改邮件平台",
    edit_msg_plat : "修改消息平台",
    smtp_addr : "SMTP地址",
    smtp_port : "SMTP端口",
    ssl_state : "SSL状态",
    one_push : "个推",
    fcm : "FCM",
    access_id : "平台应用ID",
    access_key : "平台应用 KEY",
    secret_key : "平台应用Secret KEY",
    key_id : "平台KEYID",
    key_secret : "平台密码",
    sign_name : "短信签名",
    pls_input_name : "请输入名字",
    pls_input_smtp_addr : "请输入SMTP地址",
    pls_input_smtp_port : "请输入SMTP端口",
    pls_input_ssl : "请输入SSL",
    pls_input_userid : "请输入用户ID",
    ssl : "SSL",
    userid : "用户ID",
    body : "内容",
    lang : "语言",
    pls_input_body : "请输入内容",
    pls_input_code  : "请输入编码",
    setting_email_bind : "邮件绑定",
    setting_msg_bind : "  消息绑定",
    setting_sms_bind : "  短信绑定",
    email_template : "邮件模板",
    email_platform : "邮件平台",
    sms_template : "短信模板",
    sms_platform : "短信平台",
    msg_platform : "消息平台",
    msg_template : "消息模板",
    dont_bind : "不绑定",
    is_bind : "绑定中",
    no_role_see : "无权限查看",
    unbind : "解绑",
    channel : "通道",

    "South Sudan" : "South Sudan",
    Somaliland : "Somaliland",
    Kosovo : "Kosovo",
    Vietnam : "Vietnam",
    "West Bank" : "West Bank",
    Afghanistan : "阿富汗",
    Angola : "安哥拉",
    Albania : "阿尔巴尼亚",
    "United Arab Emirates" : "阿拉伯联合酋长国",
    Argentina : "阿根廷",
    Armenia : "亚美尼亚",
    Australia : "澳大利亚",
    Austria : "奥地利",
    Azerbaijan : "阿塞拜疆",
    Burundi : "布隆迪",
    Belgium : "比利时",
    Benin : "贝宁",
    "Burkina Faso" : "布基纳法索",
    Bangladesh : "孟加拉国",
    Bulgaria : "保加利亚",
    "The Bahamas" : "巴哈马",
    "Bosnia and Herzegovina" : "波斯尼亚和黑塞哥维那",
    Belarus : "白俄罗斯",
    Belize : "伯利兹",
    Bermuda : "百慕大群岛",
    Bolivia : "玻利维亚",
    Brazil : "巴西",
    Brunei : "文莱",
    Bhutan : "不丹",
    Botswana : "博茨瓦纳",
    "Central African Republic" : "中非共和国",
    Canada : "加拿大",
    Switzerland : "瑞士",
    Chile : "智利",
    China : "中国",
    "Ivory Coast" : "科特迪瓦共和国",
    Cameroon : "喀麦隆",
    "Democratic Republic of the Congo" : "刚果民主共和国",
    "Republic of the Congo" : "刚果共和国",
    Colombia : "哥伦比亚",
    "Costa Rica" : "哥斯达黎加",
    Cuba : "古巴",
    "Northern Cyprus" : "北塞浦路斯土耳其共和国",
    Cyprus : "塞浦路斯",
    "Czech Republic" : "捷克",
    Germany : "德国",
    Djibouti : "吉布提",
    Denmark : "丹麦",
    "Dominican Republic" : "多米尼加共和国",
    Algeria : "阿尔及利亚",
    Ecuador : "厄瓜多尔",
    Egypt : "埃及",
    Eritrea : "厄立特里亚",
    Spain : "西班牙",
    Estonia : "爱沙尼亚",
    Ethiopia : "埃塞俄比亚",
    Finland : "芬兰",
    Fiji : "斐济",
    France : "法国",
    Gabon : "加蓬",
    UnitedKingdom : "英国",
    Georgia : "格鲁吉亚",
    Ghana : "加纳",
    Guinea : "几内亚",
    Gambia : "冈比亚",
    "Guinea Bissau" : "几内亚比绍",
    Greece : "希腊",
    Guatemala : "危地马拉",
    "French Guiana" : "法属圭亚那",
    Guyana : "圭亚那",
    Honduras : "洪都拉斯",
    Croatia : "克罗地亚",
    Haiti : "海地",
    Hungary : "匈牙利",
    Indonesia : "印度尼西亚",
    India : "印度",
    Ireland : "爱尔兰",
    Iran : "伊朗",
    Iraq : "伊拉克",
    Iceland : "冰岛",
    Israel : "以色列",
    Italy : "意大利",
    Jamaica : "牙买加",
    Jordan : "约旦",
    Japan : "日本",
    Kazakhstan : "哈萨克斯坦",
    Kenya : "肯尼亚",
    Kyrgyzstan : "吉尔吉斯坦",
    Cambodia : "柬埔寨",
    "South Korea" : "韩国",
    Kuwait : "科威特",
    Laos : "老挝",
    Lebanon : "黎巴嫩",
    Liberia : "利比里亚",
    Libya : "利比亚",
    "Sri Lanka" : "斯里兰卡",
    Lesotho : "莱索托",
    Lithuania : "立陶宛",
    Luxembourg : "卢森堡",
    Latvia : "拉脱维亚",
    Morocco : "摩洛哥",
    Moldova : "摩尔多瓦",
    Madagascar : "马达加斯加",
    Mexico : "墨西哥",
    Mali : "马里",
    Myanmar : "缅甸",
    Montenegro : "黑山",
    Mongolia : "蒙古",
    Mozambique : "莫桑比克",
    Mauritania : "毛里塔尼亚",
    Malawi : "马拉维",
    Malaysia : "马来西亚",
    Namibia : "纳米比亚",
    "New Caledonia" : "新喀里多尼亚",
    Greenland : "格陵兰 ",
    "Falkland Islands": "马尔维纳斯群岛",
    "French Southern and Antarctic Lands": "法属南部领地",
    Macedonia : "前南马其顿",
    Niger : "尼日尔",
    Nigeria : "尼日利亚",
    Nicaragua : "尼加拉瓜",
    Netherlands : "荷兰",
    Norway : "挪威",
    Nepal : "尼泊尔",
    "New Zealand" : "新西兰",
    Oman : "阿曼",
    Pakistan : "巴基斯坦",
    Panama : "巴拿马",
    Peru : "秘鲁",
    Philippines : "菲律宾",
    "Papua New Guinea" : "巴布亚新几内亚",
    Poland : "波兰",
    "Puerto Rico" : "波多黎各",
    "North Korea" : "朝鲜",
    Portugal : "葡萄牙",
    Paraguay : "巴拉圭",
    Qatar : "卡塔尔",
    Romania : "罗马尼亚",
    Russia : "俄罗斯",
    Rwanda : "卢旺达",
    "Western Sahara" : "西撒哈拉",
    "Saudi Arabia" : "沙特阿拉伯",
    Sudan : "苏丹",
    Senegal : "塞内加尔",
    "Solomon Islands" : "所罗门群岛",
    "Sierra Leone" : "塞拉利昂",
    "El Salvador" : "萨尔瓦多",
    Somalia : "索马里",
    "Republic of Serbia" : "塞尔维亚",
    Suriname : "苏里南",
    Slovakia : "斯洛伐克",
    Slovenia : "斯洛文尼亚",
    Sweden : "瑞典",
    Swaziland : "斯威士兰",
    Syria : "叙利亚",
    Chad : "乍得",
    Togo : "多哥",
    Thailand : "泰国",
    Tajikistan : "塔吉克斯坦",
    Turkmenistan : "土库曼斯坦",
    "East Timor" : "东帝汶",
    "Trinidad and Tobago" : "特立尼达和多巴哥",
    Tunisia : "突尼斯",
    Turkey : "土耳其",
    "United Republic of Tanzania" : "坦桑尼亚",
    Uganda : "乌干达",
    Ukraine : "乌克兰",
    Uruguay : "乌拉圭",
    "United States" : "美国",
    Uzbekistan : "乌兹别克斯坦",
    Venezuela : "委内瑞拉",
    Vanuatu : "瓦努阿图",
    Yemen : "也门",
    "South Africa" : "南非",
    Zambia : "Zambia",
    Zimbabwe : "津巴布韦",
    Liechtenstein : "Liechtenstein",
    "W Sahara" : "W. Sahara",
    Serbia : "Serbia",
    Aland : "Aland",
    Andorra : "Andorra",
    AmericanSamoa : "American Samoa",
    CaymanIs : "开曼群岛",
    CI : "开曼群岛",
    VN : "越南",
    AF : "阿富汗",
    AO : "安哥拉",
    AL : "阿尔巴尼亚",
    AE : "阿拉伯联合酋长国",
    AR : "阿根廷",
    AM : "亚美尼亚",
    AU : "澳大利亚",
    AT : "奥地利",
    AZ : "阿塞拜疆",
    BI : "布隆迪",
    BE : "比利时",
    BJ : "贝宁",
    BF : "布基纳法索",
    BD : "孟加拉国",
    BG : "保加利亚",
    BS : "巴哈马",
    BA : "波斯尼亚和黑塞哥维那",
    BY : "白俄罗斯",
    BZ : "伯利兹",
    BM : "百慕大群岛",
    BO : "玻利维亚",
    BR : "巴西",
    BN : "文莱",
    BT : "不丹",
    BW : "博茨瓦纳",
    CF : "中非共和国",
    CA : "加拿大",
    CH : "瑞士",
    CL : "智利",
    CN : "中国",
    KT : "科特迪瓦共和国",
    CM : "喀麦隆",
    CD : "刚果民主共和国",
    CG : "刚果共和国",
    CO : "哥伦比亚",
    CR : "哥斯达黎加",
    CU : "古巴",
    // TR : "北塞浦路斯土耳其共和国",
    CY : "塞浦路斯",
    CZ : "捷克",
    DE : "德国",
    DJ : "吉布提",
    DK : "丹麦",
    DO : "多米尼加共和国",
    DZ : "阿尔及利亚",
    EC : "厄瓜多尔",
    EG : "埃及",
    ER : "厄立特里亚",
    ES : "西班牙",
    EE : "爱沙尼亚",
    ET : "埃塞俄比亚",
    FI : "芬兰",
    FJ : "斐济",
    FR : "法国",
    GA : "加蓬",
    GB : "英国",
    GE : "格鲁吉亚",
    GH : "加纳",
    GN : "几内亚",
    GM : "冈比亚",
    GW : "几内亚比绍",
    GR : "希腊",
    GT : "危地马拉",
    GF : "法属圭亚那",
    GY : "圭亚那",
    HN : "洪都拉斯",
    HR : "克罗地亚",
    HT : "海地",
    HU : "匈牙利",
    ID : "印度尼西亚",
    IN : "印度",
    IE : "爱尔兰",
    IR : "伊朗",
    IQ : "伊拉克",
    IS : "冰岛",
    IL : "以色列",
    IT : "意大利",
    JM : "牙买加",
    JO : "约旦",
    JP : "日本",
    KZ : "哈萨克斯坦",
    KE : "肯尼亚",
    KG : "吉尔吉斯坦",
    KH : "柬埔寨",
    KR : "韩国",
    KW : "科威特",
    LA : "老挝",
    LB : "黎巴嫩",
    LR : "利比里亚",
    LY : "利比亚",
    LK : "斯里兰卡",
    LS : "莱索托",
    LT : "立陶宛",
    LU : "卢森堡",
    LV : "拉脱维亚",
    MA : "摩洛哥",
    MD : "摩尔多瓦",
    MG : "马达加斯加",
    MX : "墨西哥",
    ML : "马里",
    MM : "缅甸",
    ME : "黑山",
    MN : "蒙古",
    MZ : "莫桑比克",
    MR : "毛里塔尼亚",
    MW : "马拉维",
    MY : "马来西亚",
    NA : "纳米比亚",
    NC : "新喀里多尼亚",
    GL : "格陵兰 ",
    FK : "马尔维纳斯群岛",
    TF : "法属南部领地",
    MK : "前南马其顿",
    NE : "尼日尔",
    NG : "尼日利亚",
    NI : "尼加拉瓜",
    NL : "荷兰",
    NO : "挪威",
    NP : "尼泊尔",
    NZ : "新西兰",
    OM : "阿曼",
    PK : "巴基斯坦",
    PA : "巴拿马",
    PE : "秘鲁",
    PH : "菲律宾",
    PG : "巴布亚新几内亚",
    PL : "波兰",
    PR : "波多黎各",
    KP : "朝鲜",
    PT : "葡萄牙",
    PY : "巴拉圭",
    QA : "卡塔尔",
    RO : "罗马尼亚",
    RU : "俄罗斯",
    RW : "卢旺达",
    EH : "西撒哈拉",
    SA : "沙特阿拉伯",
    SD : "苏丹",
    SN : "塞内加尔",
    SB : "所罗门群岛",
    SL : "塞拉利昂",
    SV : "萨尔瓦多",
    SO : "索马里",
    RS : "塞尔维亚",
    SR : "苏里南",
    SK : "斯洛伐克",
    SI : "斯洛文尼亚",
    SE : "瑞典",
    SZ : "斯威士兰",
    SY : "叙利亚",
    TD : "乍得",
    TG : "多哥",
    TH : "泰国",
    TJ : "塔吉克斯坦",
    TM : "土库曼斯坦",
    TL : "东帝汶",
    TT : "特立尼达和多巴哥",
    TN : "突尼斯",
    TR : "土耳其",
    TZ : "坦桑尼亚",
    UG : "乌干达",
    UA : "乌克兰",
    UY : "乌拉圭",
    US : "美国",
    UZ : "乌兹别克斯坦",
    VE : "委内瑞拉",
    VU : "瓦努阿图",
    YE : "也门",
    ZA : "南非",
    ZM : "Zambia",
    ZW : "津巴布韦",
    SG : "新加坡",
    Singapore : "新加坡",
    cny_fee : "交易金额(¥)",
    usd_fee : "交易金额($)",
    order_count : "订单总数",
    unknown_zone : "未知地区",
    comp_top5 : "公司订单TOP5",
    app_top5 : "APP订单TOP5",
    pey_type_rate : "支付方式占比",
    comp_info_before : "近期各公司数据",
    cny : "人民币",
    usd : "美金",
    total_count : "订单",
    app_detail : "APP 详细",
    comp_detail : "公司 详细",

    ver_name : "版本名称",
    pls_input_ver_name : "请输入版本名称",
    oat_zone : "地区",
    other_zone : "其他地区",
    pending : "待处理",
    solved : "已解决",
    send_desc : "发送详情",
    link_param : "链接参数",
    msg_param : "信息参数",
    is_succ : "成功",
    is_fail : "失败",
    link_id : "关联数据",
    email_address : "邮箱地址",
    import_date : "导入时间",
    un_bind : "不绑定",
    bind_other_order_id : "绑定第三方",
    add_platform : " 添加平台",
    edit_platform : "修改平台",
    quectel : "移远",
    app_secret : "APP密钥",
    setting_quectel_bind : "绑定移远",
    platform_type : "平台类型",
    setting_flow : "流量配置",
    is_outfo_date : "已过期",
    is_useing : "正在使用",
    end_time : "结束时间",
    file : "文件",
    select_file : "选择文件",
    effect_time : "有效时间",
    un_use : "未使用",
    valid_renewa : "有效续费",
    dev_import : "记录",
    comp_short_name : "公司简称",
    edit_detail : "详情查询",
    app_version : "版本管理",
    un_open : "不开启",
    save : "保存",
    add_new : "新增",
    download_url : "下载地址",
    now_use_version : "当前发布版本",
    none : "无",
    histroy_version : "历史版本",
    real_qrcode_url : "二维码真实地址",
    image_download : "图片下载",
    qrcode : "二维码",
    set_app_download : "配置下载地址",
    view_qrcode : "预览二维码",
    qrcode_info : "用户扫码后，系统会自动根据用户的手机系统环境，跳转到对应的下载地址",
    menu_cn_name : "中文名称",
    year : "年",
    add_new_app_set : "添加APP配置",
    umeng : "友盟",
    no_active : "未激活",
    is_active : "已激活",
    ser_sys : "体系",
    fri_active_time : "首次激活时间",
    last_online_time : "最近上线时间",
    outline : "下线",
    setting_server : "服务配置",
    grpc_addr : "GRCP地址",
    http_addr : "HTTP地址 ",
    reg_timestamp : "注册时间",
    latest_timestamp : "更新时间",
    app_platform : "平台",
    create_count : "今日新增人数",
    yesterday_create_count : "昨日新增人数",
    update_count : "今日活跃人数",
    yesterday_update_count : "昨日活跃人数",
    total_count_num : "至今累计用户",
    MP: "南马利亚那群岛",
    HK  :"香港",
    BH : "波黑",
    TW : "台湾",
    MO : "澳门",
    you_will_delete : "你是否确定解绑以下相关的设备",
    app_id_or_name : "APP ID/名称",
    bind_state : "绑定状态",
    fact_price : "实际收款", 

    binding : "已绑定",
    un_binding : "未绑定",
    user_flow : "用户趋势",
    new_people : "新增用户",
    log_people : "活跃用户",
    total_people : "累计用户",
    today : "今日",
    yesterday : "昨日",
    top_ten_zone : "Top10国家/地区",
    memory_rate : "已使用内存",
    disk_rate : "已使用硬盘",
    info_email : "通知邮箱",
    un_overdue : "未过期",

    dev_sys : "所属系统",
    dev_flow : "数据变化",
    new_dev  : "新增设备",
    log_dev : "活跃设备",
    total_dev : "累计设备",
  },
  en : {
    port : "Port",
    net_type : "Net type",
    srv_id : "Service ID",
    srv_ip : "Service IP",
    srv_zone: "Service country",
    dev_state_0          : 'offline',
    dev_state_1          : 'online',
    net_type_0           : 'unknown',            
    net_type_1           : 'public network',     
    net_type_2           : 'full-cone nat',      
    net_type_3           : 'ip-restricted nat',  
    net_type_4           : 'port-restricted nat',
    net_type_5           : 'symmetric nat',      
    search_by_ip: "Search by IP",
    deivce_code: "DK",
    deivce_code_placehoder: "Unfilled will be generated by the server",
    template_name         : "Template name",
    themes                : "Themes",
    problem_description   : "Problem Description",
    contact               : "Contact",
    submit                : "Submit",
    feedback_from_1       : "Please describe the problem in detail so that we can provide better help",
    feedback_from_2       : "Optional, that we can contact you",
    feedback_time         : "Feedback time",
    have_feed             : "Have feedback",
    process               : "In the processing",
    resolved              : "resolved",
    viewinf_commun_record : "See the feedback",
    submit_feedback       : "Submit feedback",
    problem_feedback      : "The problem of feedback",
    details_feedback      : "Feedback the details",
    gateway_info : "Multi-channel products can manage sub-device channels.",
    role_setting : "Role setting",
    need_xlsx: "must upload xlsx file",
    source_area_code : "Source area code",
    object_area_code : "Object area code",
    unselect : "no sys",
    the_code : "Code",
    source_area_type : "Source area type",
    object_area_type : "Object area type",
    continent :"continent",
    country   :"country",
    SC : "Seychelles",
    Seychelles : "Seychelles",
    product_list : "Product list",
    fir_update : "Firmware update",
    level : "Level",
    node_type : "Node type",
    net_video : "Net video",
    video : "Video",
    device : "Single Channel",
    gateway : "Multi Channel",
    all_: "all",
    cloud_server : "Cloud server",
    cloud_service : "Cloud services",
    document : "file",
    asia : "Asia",
    europe : "Europe",
    africa : "Africa",
    latin_america : "Latin America",
    north_america : "North America",
    oceania : "Oceania",
    antarctica : "Antarctica",

    func_conf : "function configuration",
    "United Kingdom":"United Kingdom",
    MT : "Malta",
    edit_stock: "Edit stock",
    stock_type_mng : "Good type manage",
    user_statis: "User statistics",
    push_setting : "Push Setting",
    modify_comp : "Edit company",
      input_in_this : "Input in this and Enter to add it",
      icon : "Icon",
      push_record :"Push record",
      payment_platform : "Payment platform",
      tips : "Tips",
      stock : "Stock",
      selected : "Selected",
      dev_load : "Device load",
      lic_load : "LIC load",
      tcp_addr  : "TCP address",
      link_addr : "Link address",
      master: "master",
      unbind_success: "Unbind success",
      no_dev_unbind : "No device can unbind",
      click_select_file : "Click and select file",
      sub : "sub",
      lang_i18n : "en",
      device_info: "Device info",
      search_by_uid : "Search by UID",
      search_by_mac : "Search by MAC",
      order_info: "Order info", 
      push_platform_set : "Push platform configuration ",
      push_template_set : "Push template configuration",

      check_delete : `Are you sure you want to delete "%1%"?`,
      main_menu : "Main menu",
      sub_menu : "Sub menu",
      unknown_error : "Unknown error",
      error_code_406 : "Error params",
      login_success  : "Login success",
      home_page            : "Home",
      login : "login",
      umeye : "UM IoT Platform",
      personal_info_mini: "P",
      edit_app_set: "Edit APP set",
      edit_attr : "Edit attribute",
      del_attr : "Delete attribute",
//       master : " master",
// sub : "sub",
// tcp_addr : "TCP address",
// link_addr : "Link address",
// dev_load : "Device load",
// lic_load : "LIC load",
// #公用翻译部分",
// #error code ajax callback content data.h.error",
umeye_platform : "Open Server",
http_error_513 : "Your account has no permission.",
http_error_512 : "Wrong password",
http_error_514 : "Wrong account or password. Pls try again.",
http_error_514_2 : "Wrong password",
http_error_515 : "The ID is not unique.",
http_error_516 : "The account has no related company info..",
http_error_517 : "Disable account",
http_error_518 : "Overdue account ",
http_error_519 : "Unknown status type",
http_error_409 : "The ID has been added.",
http_error_409_2 : "The user name already exisits.",
e_515_room_has_owner : "The room is owned by an owner.",
login_error : "Login failed",
email_error : "Email format error",
phone_error : "Phone No. format error",
map_error : "Map error",
pls_input_post_num : "Pls input a number greater than zero.",
pay_success : "Congragulation for payment success!",
num_0_01_to_5000 : "Recharge ranging from RMB0.01 to RMB5000",
shop_server : "Open Server",
unknow_state : "Unknow type",
now_loading : "Loading...",
check_logout : "Sure to logout?",
logout : "Logout",
payment_component : "Payment service",
// unknow_state : "Unknown",
upload_success : "Uploaded successfully",
invalid_string : "Invalid strings",
userid_email_phone_is_used : "User ID, email or phone is already occupied. Pls use an unused email or phone.",

logout_success : "Logout successfully",
pls_sel_right_node : "Pls select a node to revice",
control_mng : "Console management",
product_server : " Product and service",
menu_url : "Menu URL",
pls_input_menu_url : "Pls input menu URL (selected)",
// #Login Page",
account : "Account",
password : "Password",
pls_input_password : "Pls input password",
verificationCode :"Verification code",
signin_msg_id1 : "Invalid code! Pls re-enter or refresh the page.",
// login : "Login",

// #Home Page",
smart_community_server :"Smart community service",
userInfo:"User info,",
reset_passwd:"Reset password",
system_prompt:  "System prompt",
exitTip : "Sure to exit?",
cancle : "Cancel",
sure : "Sure",
exit : "Exit",
index : "Index",
start :  "Start",
end : "End",
add : "Add",
search : "Search",
hide : "Hide",
reset : "Reset",
operate : "Operate",
setting : "Setting",
edit : "Edit ",
delete : "Delete",
batch_delete : "Batch_delete",
pls_select : "Pls select",
none_data : "No data",
total : "Total",
prev_page : "Previous page",
next_page : "Next page",
name : "Name",
en_name : "English name",
state : "State",
normal : "Normal",
open : "Open",
close : "Close",
disable : "Disable",
overdue : "Overdue",
is_sure_del : "Sure to delete?",
is_sure_del_has_node : "This node has child nodes. Sure to delete?",
created_thing_time : "Creating time",
select_time_range : "Select time range",
time_range : "Time range",
create_success : "Created successfully ",
add_success : "Added successfully ",
add_success_2 : 'Added successfully! You can stay and add again, or click "cancel" to close the page.',
edit_success : "Edited successfully ",
delete_success : "Deleted successfully",
setup_success : "Setup successfully ",
add_fail : "Adding failed",
edit_fail : "Revising failed",
delete_fail : "Deleting failed",
setup_fail : "Setup failed",
get_error : "Failed to obtain info.",
search_type : "Search type",
all_select : "Select all",
pls_input : "Pls input",
pls_check_verify : "Pls check the required fields filled completely",
pls_check_verify_2 : "Pls check the fillings complying with the specifications",
pls_input_num_id : "Pls input a numeric ID",
pls_input_num : "Pls input a number",
pls_input_phone : "Pls input phone number in the correct format",
pls_input_phone_11 : "Pls input an 11-digit phone number",
pls_input_email : "Pls input an email in the correct format",
pls_input_id_card : "Pls input ID in the correct format",
china : "China",
now_here : "Location",
is_loading : "Loading",
to : "To",
start_end_time : "Start and end time",
pls_select_state : "Pls select state",
show_all : "Show all",
show : "Show",
only_show_level_1 : "Only show level 1",
upload : "Upload",
upload_fail : "Uploading failed",
upload_error_type : "Format erroe of uploaded files",
more : "More",
// #Menu Level 1",
company_manage : "Company management",
estate_manage : "Property management",
advertise_manage : "Advertising management",
device_manage : "Device management",
data_analyse : "Data analysis",

// #Menu Level 2",
user_manage : "User management",
security_setting : "Security setting",
system_setting : "System setting",
community_manage : "Community management",
security_manage : "Security management",
statistics_manage : "Statistics management",
list_setting : "Menu configuration",
community_advertise : "Community advertisement",
register_manage : "Register management",

// #Menu Level 3",
business_manage : "Business management",
personal_info : "Personal information",
modify_pw : "Modify password",
modify_info : "Modify menu",
role_manage : "Role management",
// community_manage : "Community management",
owner_manage : "Owner management",
payment_manage : "Payment management",
notice_manage : "Notice management",
call_manage : "Locked-screen call management",
communicate_carousel : "Community carousel",
periphery_server : "Peripheral service",
product_manage : "Product management",
data_general : "Data analysis",
area_spread : "Area spread",
user_type : "Role type",
indiv_operat : "Self-employed",
company : "Company",
user : "User",

// #Company Management",
confirm_password : "Confirm passford",
pls_input_confirm_password : "Pls input password again",

    // #Customer Management",
    add_company : "Add company",
    edit_company : "Edit company",
    belong_company : "Company belonged to",
    pls_select_belong_company : "Pls select a company",
    login_name : "User nickname",
    pls_input_login_name : "Pls input user nickname",
    phone : "Phone number",
    company_id : "Company ID",
    // user_type : "Role type",
    // indiv_operat : "Self-employed",
    // company : "Company",
    company_type : "Company type",
    pls_select_company_type : "Pls select company type",
    created_time : "Registration time",
    user_name : "User name",
    pls_input_user_name : "Pls input the user name",
    user_id : "User ID",
    // pls_input_user_name : "Pls input user ID",
    account_state : "Account state",
    email : "Email",
    user_role : "User role",
    pls_select_user_role : "Pls select user role",
    remarks : "Remarks",
    pls_input_remarks : "Pls input remarks",
    add_user : "Add user",
    edit_user : "Edit user",
    company_name : "Company name",
    pls_input_company_name : "Pls input company name",
    agent_type : "Agent type",
    pls_sel_agent_type : "Pls select agent type",
    map_position : "Map position",
    company_address : "Company address",
    pls_input_company_address : "Pls input company address",
    leading_name : "Leading person name",
    pls_input_leading_name : "Pls input the leading name",
    sort_num : "Sort number",
    pls_input_sort_num : "Pls input a numeric sort number, if not, it will be placed at the end of the registration by default",
    pls_input_sort_num_2 : "Pls input the numeric sort number",
    super_admin : "Super administrator",
    admin : "Administrator",
    factory : "Manufacturer",
    ex_agents : "Total agent",
    agents : "Agent type",
    trader : "Distributor",
    property : "Property",
    user_only_company : "Only users under this company",
    user_all_company : "Users of this company and its subsidiaries",
    validity_time : "Validity period",
    pls_input_validity_time : "Please confirm the expiration date",
    date : "Date",
    add_user_com : "Add enterprise user",
    add_user_self : "Add individual user",
    // company_id : "Company ID",
    too_long_4 : "Only 4 characters at most",
    developer : "Developer",
    cust_comp : "Client company",
    sub_cust_comp : "Customer subordinate marketing company",

    // #Security Setting",
    ip_address : "IP address",
    update_date : "Update time",
    old_password : "Old password",
    pls_input_old_password : "Pls input old password",
    new_password : "New password",
    pls_input_new_password : "Pls input new password",
    password_no_equal : "Diffrent passwods",

    // #Role Management",
    add_role : "Add role",
    edit_role : "Edit role",
    del_role : "Delete role",
    parent_node : "Parent node",
    none_parent_node : "None-parent node",
    pls_input_role_name : "Please enter the role name",
    // en_name : "English name",
    pls_input_en_name : "Please enter an English name",
    role_form_tips1 : "Whether this menu or operation is displayed in the system menu",
    role_list : "Role menu",

     // #Menu Management",
     pls_sel_node : "Please select node",
     is_last_child : 'This menu cannot continue to add submenuspls_input_role_name : "Please enter the role name" ',
     // en_name : "English name",
     // pls_input_en_name : "Please enter an English name",
     // role_form_tips1 : "Whether this menu or operation is displayed in the system menu",
     // role_list : "Role menu",

     // #Menu Management",
     // pls_sel_node : "Please select node",
     // is_last_child : "This menu cannot continue to add submenus",

    // #Asset Management",
    application_time : "Application time",
    now_withdrawal : "Withdraw now",
    now_recharge : "Recharge now",
    withdrawal_title : "Withdrawal (please enter the withdrawal information accurately to facilitate faster payment!)",
    cardholder : "Cardholder name",
    link_phone : "Contact phone",
    pls_input_cardholder : "Please enter the cardholder name",
    pls_input_link_phone : "Please enter the phone number",
    pls_input_withdrawal_amount : "Please enter the withdrawal amount",
    belong_bank : "Belong to bank",
    pls_input_belong_bank : "Please enter your bank",
    bank_card_num : "Bank card number",
    pls_input_bank_card_num : "Please enter the bank card number",
    open_account_place : "Account opening location",
    pls_input_open_account_place : "Please enter the account opening location",
    balance : "Balance",
    your_balance : "Your current balance is",
    rmb : "Yuan",
    account_expenses_type : "Type of income and expenditure",
    account_expenses_money : "Income and expenditure amount",
    operation_time : "Operation time",
    recharge_money : "Recharge the wallet balance",
    recharge_num : "Recharge amount",
    pls_input_recharge_num : "Please enter the recharge amount",
    recharge_type : "Recharge type",
    wechart : "WeChat",
    close_layer : "Close page",
    income_expen_type : "Income and expenditure type",
    pls_select_income_expen_type : "Please select",
    amount_rmb : "Amount (unit: yuan)",
    withdrawal_amount : "Withdrawal amount",
    procedure_cost : "Procedure cost",
    all_total_cost : "Actual cost",
    examine : "Examine",
    details : "Details",
    payment : "Income and expenditure",
    no_audited : "Pending audit",
    first_audit_pass : "First audit pass",
    first_audit_no_pass : "First audit failed",
    audit_pass : "Audit passed",
    audit_no_pass : "Audit has not passed",
    recharge : "Recharge",
    cash_deduction : "Withdrawal deduction",
    cash_withdrawal : "Withdrawal and refund",
    alipay : "Alipay",
    paypal : "PayPal",
    platform_publicKey : "Platform payment public key",
    platform_privateKey : "Platform payment private key",
    platform_return_url : "Synchronization callback address",
    platform_notify_url : "Asynchronous callback address",
    platform_appid : "Appid configuration item",
    config_ids : "Configuration ID",
    set_payment : "Configure payment mode",

    // # App management",
        client_type : "Client type",
        app_id : "APP ID",
        app_name : "APP name",

    // #Property Management",
    // #Community Management",
    community_name : "Community name",
    pls_input_community_name : "Please enter the community name",
    community_phone : "Community phone",
    pls_input_community_phone : "Please enter the community phone",
    add_community : "Add community",
    edit_community : "Edit community",
    community_short_code : "Community short code",
    belong_area : "Belong area",
    pls_input_belong_area : "Please enter the area where the community belongs",
    estate_cost_setting : "Estate cost setting",
    build_name : "Building name",
    build_order : "Building sequence",

    add_build : "Add building",
    edit_build : "Edit building",
    batch_add : "Batch add",

    add_floor : "Add floor",
    edit_floor : "Edit floor",

    add_room : "Add room",
    edit_room : "Edit room",

    // build_name : "Building name",
    pls_input_build_name : "Please enter the building name",
    // build_order : "Building sequence",
    pls_input_build_order_num : "Building sequence number",

    floor_name : "Floor name",
    pls_input_floor_name : "Please enter floor name",
    floor_order : "Floor sequence",
    pls_input_floor_order_num : "Floor sequence number",

    room_name : "Room name",
    pls_input_room_name : "Please enter the room name",
    room_order : "Room sequence",
    pls_input_room_order_num : "Room order number",

    room_size : "Room size",
    is_using : "Whether to enable",
    floor_count : "Number of floors",
    pls_input_floor_count : "Please enter the number of floors to be added",
    room_count : "Number of rooms per floor",
    pls_input_room_count : "Please enter the number of rooms on each floor to be added",
    room_square : "Room area",
    pls_input_room_square : "Please enter the room area",
    room_is_use : "Room status",
    is_use : "Enable",
    is_not_use : "Not enabled",

    pay_state : "Payment method",
    no_open_pay : "Do not open payment",
    platform : "Platform collection",
    pay_code : "Receipt code",
    fee_square : "Property fee per square",
    pls_input_fee_square : "Please enter the property fee per square",
    fee_other : "Other fees",
    pls_input_fee_other : "Please enter other fees",
    fee_door : "Disable access control to open the door without paying",
    // fee_door : "Disable access control to open the door without paying",
    form_info_payment_1 : "After the 1st of each month, turn on or off the property payment, and it will take effect on the 1st of the following month."  ,

  
    
    form_info_payment_2 : "For example, when property payment is started on March 2, it will take effect on April 1.",
    overdue_day : "Overdue days of property fees",
    pls_input_overdue_day_num : "Please enter the overdue days of the property fee",
    pay_code_2 : "Alipay/WeChat payment code",
    upload_img : "Upload image",
    prop : "Owner",
    prop_name : "Proprietor name",
    pls_input_prop_name : "Please enter the owner name",
    prop_type : "Proprietor type",
    prop_state : "Proprietor state",
    prop_phone : "Wwner phone",
    prop_room : "Community account number",
    add_prop : "Add proprietor",
    put_in_prop : "Import owner",
    put_out_prop : "Export proprietor",
    // add_prop : "Add proprietor",
    edit_prop : "Edit proprietor",
    prop_sheets : "Proprietor sheet",
    pls_sel_build : "Please select a building",
    pls_sel_floor : "Please select floor",
    sel_room : "Select room",
    room : "Room",
    pls_sel_room : "Please select a room",
    del_room : "Delete holding room",

    owner_phone : "Owner phone",
    pls_input_owner_phone : "Please enter the owner phone",

    // #Announcement Management",
    notice_title : "Notice title",
    pls_input_notice_title : "Please enter the title of the announcement",
    belong_community : "Belongs to the community",
    notice_content : "Notice content",
    pls_input_notice_content : "Please enter the notice content",

    start_time : "Start time",
    sel_time : "Select time",
    add_notice : "Add notice",
    edit_notice : "Edit notice",
    pls_sel_community : "Please select a community",
    id_card_number : "ID number",
    pls_input_id_card_number : "Please enter ID card number",

    // #Payment Management",
    is_pay : "Whether to pay",
    pay : "Paid",
    no_pay : "Not paid",
    fee_value : "Payment fee",
    fee_type : "Fee type",
    mater_fee : "Property management fee",
    other_fee : "Other fees",
    prop_position : "Area range",
    do_create : "Whether to create",
    create_material_cost : "Generate monthly property management costs",
        
// #Menu Configuration",
    add_main_menu : "Add the first level menu",
    add_sub_menu : "Add a submenu to the selected menu",
    edit_menu : "Modify the selected menu",
    delete_menu : "Delete the selected menu",
    menu_id : "Menu ID",
    pls_input_role_id : "Please enter the numeric ID of the menu",
    menu_key : "Menu KEY",
    pls_input_menu_key : "Please enter the KEY of the menu",
    menu_name : "Menu name",
    pls_input_menu_name : "Please enter the menu name",
    role_id : "Role ID",
    // pls_input_role_id : "Please enter the role's digital ID",
    role_name : "Role name",
    role_type : "Role type",
    system_role : "System role",
    defined_role : "Custom role",
    role_state : "Role state",
    select_face : "Select face",
    platform_appKey : "Platform KEY",
    platform_appSecret : "Platform Secret",
    platform_endPoint : "Regional node",
    platform_port : "Access port",
    platform_bucket : "Storage node",
    platform_file_prefix : "File prefix",
    platform_store_path : "Storage path",
    platform_id : "Platform ID",
    platform_type_name : "Platform type name",
    // belong_area : "Belong area",
    pls_input_platform_appKey : "Please enter the platform KEY",
    pls_input_platform_appSecret : "Please enter the platform Secret",
    pls_input_platform_endPoint : "Please enter the region node",
    pls_input_platform_port : "Please enter the access port",
    pls_input_platform_bucket : "Please enter the storage node",
    pls_input_platform_file_prefix : "Please enter the file prefix",
    pls_input_platform_store_path : "Please enter the storage path",
    pls_select_platform_type : "Please select cloud storage type",
    platform_type : "Cloud storage type",
    shopping_mng : "Cloud storage management",
    // check_logout : "Do you want to log out?",
    // logout : "Logout",
    more_info : "More",
        
    menu_type : "Menu type",
    server_type : "Service type",
    pls_input_server_type : "Please enter the service type",
    server_key : "Service key",
    pls_input_server_key : "Please enter the service key",
    server_name : "Service name",
    pls_input_server_name : "Please enter the service name",
    add_server : "Add service",
    edit_server : "Modify service",
    platform_order_id : "Order ID",
    // add_user_com : "Add enterprise user",
    // add_user_self : "Add individual user",
    // user_type : "Role type",
    // indiv_operat : "Self-employed",
    // company : "Company",

// #Ad operations",
    // #District advertising management",
    server_title : "Service title",
    advert_title : "Ad title",
    // add_server : "Add service",
    // edit_server : "Modify service",
    add_advert : "Add ad",
    edit_advert : "Edit ad",
    search_for_title : "Search by title",
    title : "Title",
    pls_input_title : "Please enter a title",
    content : "Content",
    pls_input_content : "Please enter content",
    url : "URL",
    pls_input_url : "Please enter URL",
    address : "Address",
    pls_input_address : "Please enter the address",
    img : "Picture",
    is_recommend : "Is it recommended",
    recommend : "Recommend",
    not_recommend : "Not recommended",

// #Device Managemt",
    // #Registration Management",
    add_productType : "Add device type",
    edit_productType : "Edit device type",
    type_id : "Type ID",
    type_name : "Type name",
    pls_input_type_name : "Please enter the type name",
    product_name : "Product name",
    pls_input_product_name : "Please enter the product name",
    pls_sel_product : "Please select a product",
    product_code : "Product code",
    pls_input_product_code : "Please enter the product code",
    product_type : "Product type",
    pls_sel_product_type : "Please select product type",
    add_poduct : "Add product",
    edit_poduct : "Edit product",
    product_content : "Product description",
    pls_input_product_content : "Please enter product description",
    product_tag : "Device tag",
    pls_input_product_tag : "Please enter the device tag",
    review_state : "Review state",
    not_review_state : "Not reviewed",
    via : "Pass",
    is_or_not_via : "Whether to pass",
    refuse : "Refuse",
    not_via : "Not passed",
    put_in_dev : "Import device",
    frame : "Template",
    import_course : "Import course",
    import_and_update : "Import",
    dev_code : "Device number",
    pls_input_dev_code : "Please enter the device number",
    active_time : "Activation time",
    dev_tag : "Device tag",
    pls_select_product : "Please select product first",
    dev_name : "Device name",
    pls_input_dev_name : "Please enter the device name",
    dev_type : "Device type",
    dev_area : "Device area",
    dev_snid : "Device ID",
    pls_input_dev_snid : "Please enter the device ID",
    dev_pwd : "Device password",
    pls_input_dev_pwd : "Please enter the device password",
    link_id : "Installation location",
    add_dev : "Add device",
    edit_dev : "Edit device",
    product_id : "Product ID",

// # SMS push",
    type : "Type",
    id : "ID",
    area_type : "Area type",
    param_type : "Parameter type",
    param_content : "Parameter content",
    param : "Parameter",
    // platform : "SMS platform",
    key_id : "Platform KeyId",
    pls_input_key_id : "Please enter the platform KeyId",
    key_secret : "Platform key password",
    pls_input_key_secret : "Please enter the platform key password",
    sign_name : "SMS signature",
    pls_input_sign_name : "Please enter SMS signature",
    template_code : "SMS template code",
    has_code : "Is there a verification code",

    domestic_info : "Domestic information",
    internat_info : "Hong Kong, Macao and Taiwan/International Information",
    custom_logo : "Client custom logo",
    server_ip : "Server IP",
    continent_code : "Ccontinent abbreviation",
    aliyun : "Aliyun",
    reg_notice : "Registration notice",
    adv_notice : "Activate notification",
    all : "All",
    unlimit : "Unlimited",
    search_password : "Retrieve password",
    server_victoria : "Server operation and maintenance",
    alarm_notice : "Alarm notification",

    is_has_code : "Exists",
    no_has_code : "Does not exist",
    template_lang : "Template language",
    pls_input_template_lang : "Please enter the template type",
    pls_select_template_lang : "Please select template language",
    template_body : "Template content",
    template_param : "Template parameters",
    pls_input_template_body : "Please enter the template content",
    pls_input_template_param : "Please enter template parameters",

    pls_input_param : "Please input parameters",
    add_sms : "Add SMS",

    // #Statistics",
    week : "Week",
    month : "Month",
    total_property : "Total assets (yuan)",
    total_pay : "Total payout",
    total_income : "Total income",
    user_count : "Number of users",
    partner : "Partner",
    app_user : "APP user",
    dev_count : "Number of devices",
    activation_rate : "Activation rate",
    house_count : "Number of houses",
    advertise_count : "Number of advertising transactions",
    put_in_advertise_count : "Number of ads put",

    was_pay : "Pay",
    was_income : "Income",
    data_sum : "Data summary",
    unit_yuan : "(yuan)",

    // #Product page",
    add_class : "Add category",
    sub_class : "Subclass",
    add_sub_class : "Add subclass",
    sort : "Sequence",
    class_name : "Category name",
    pls_input_class_name : "Please enter the class name",
    edit_class : "Edit class",
    add_attr : "Add attr",
    attr_name : "Attr name",
    attr : "Attr",
    attr_code : "Attr code",
    attr_value : "Attr value",

    good_name : "Product name",
    add_good : "Add goods",
    edit_good : "Edit good",
    good_stock : "Goods stock",
    stock_num : "Stock count",
    sell_num : "Number of sales",
    add_stock : "Add stock",
    comment_num : "Number of comments",
    seller_id : "Seller ID",
    stock_id : "Inventory ID",
    good_sub_name : "Inventory name",
    stock_state : "Stock state",
    orginal_fee : "Original price",
    fact_fee : "Actual price",
    limit_stock_num : "Stock limit",
    lower_shelf : "Lower shelf",
    upper_shelf : "Upper shelf",
    good_attr : "Product attributes",
    stock_attr : "Inventory attribute",
    have_limit : "Have limit",
    cant_empty : "Can't be empty",
    illegal_param : "Parameter is incorrect",

    pay_type : "Payment platform",
    // alipay : "Alipay",
    order_id : "Product order number",
    buy_id : "Purchaser ID",
    buy_list_id : "Payment order number",
    // app_id : "APP ID",
    pay_time : "Payment time",
    price : "Unit price",
    count : "Number",
    total_price : "Total price",
    vaildity_day_num : "Specific number of days for valid duration",
    validity_day : "validity period",
    undo : "Unpaid",
    paid : "Paid",
    success : "Payment successful",
    fail : "Payment failed",
    has_cancle : "Has been cancelled",
    revoke : "Order is invalid",
    save_time : "Save time",
    u_id : "Device ID",
    // platform_order_id : "Product order number",
    // app_name : "APP name",
    day : "Day",
    pay_order_id : "Payment order number",
    buy_user_id : "Buy user ID",
    umid : "Device ID",
    // logout_success : "Logout successfully",
    do_order_export : "Whether to query and export all orders under the following conditions",
    export_xls : "Export xls",
    import_help : "Import tutorial",
    order_state : "Order state",

    // #APP ",
    // client_type : "Client type",
    // app_id : "APP ID",
    // app_name : "APP name",
    app_packet : "Officially signed package name",
    edit_app_config : "Modify APP settings",
    limit_get_produce : "Product limit",
    product : "Product",

    // #Device Management",
    mapping_state : "Matching state",
    flie_dev : "Device information",
    flie_dev_type : "Device information type",
    ali_three_dev : "Ali triplet device information",
    not_mapping : "Not paired",
    is_mapping : "Paired",
    import_time : "Import time",
    show_comp_dev_xls_info : "View imported enterprise device information",
    import_state : "Import state",
    wait_sync_database : "To be imported",
    is_sync_database : "Importing",
    has_sync_database : "Has been imported",
    import_id : "Import batch id",
    import_file : "Import file",
    import_comp_id : "Import company id",
    mng_num : "Number of data",
    succ_num : "Number of successes",
    fail_num : "Number of failures",
    fail_file_url : "Import failed data",
    update_time : "Last modification time",
    is_none : "None",
    dev_pk : "Triple pk value",
    dev_dn : "Triple dn value",
    rele_comp_id : "Equipment associated company id",
    
add_app : "Add APP",
change_app : "Modify binding APP",
edit_app : "Edit APP",
// app_packet : "App official signature package package name",
create_time : "Creation time",
unknow : "Unknown",
web : "Web",
android : "Android",
ios : "Ios",
mac : "Mac",
ipad : "Ipad",
pc : "Pc",
app_ids : "Bind APP",

edit_control_panel_role : "Edit control panel",
edit_shop_page_role : "Edit shop",
edit_cloud_role : "Modify cloud storage",
edit_payment_role : "Modify payment platform",
set_user_role : "Set user role",

// developer : "Developer",
// cust_comp : "Client company",
// sub_cust_comp : "Customer subordinate marketing company",
// too_long_4 : "Only 4 characters at most",
setting_payment : "Payment configuration",
setting_cloud : "Cloud storage configuration",
setting_product : "Product configuration",


link_visual : "LinkVisual video cloud storage",
ali_oss : "Aliyun oss",
add_cloud : "Add cloud storage",
edit_cloud : "Edit cloud storage",
sales_num : "Sales quantity",
payment_name : "Name",

platform_name : "Platform name",
pls_input_platform_name : "Please enter the platform name",
zone : "Zone",
pls_input_zone : "Please input zone",
env : "Environmental information",
http_addr : "HTTP address",
region : "Location",
sys : "System",
version : "version",
inline : "Online",
offline : "Offline",
umgreg : "GReg",
umguser : "GUser",
umgopen : "GAPI",
umgmall : "GMall",
umgpay : "GPay",
umposs : "GOSS",
umiot : "GIOT",
umgpush : "GPush",
umgda : " GDA",
ota_server : "GOTA",
aws : "Amazon AWS",
status : "Status",
belong_co : "Belongs to the continent",
belong_areas : "Belonged area",
prod : "Formal",
test : "Test",
comp_key : "Company KEY",
effect_month : "Effective month duration",
orginal_usd_fee : "Original price (USD)",
fact_usd_fee : "Actual price (USD)",
pls_input_post_num_or_zero : "Please enter a number greater than or equal to 0",
dev_uid : "Device UID",
has_error_row : "Has an error",
env_type : "Environment type",
build_env : "Actual environment",
test_env : "Test environment",

only_sale_one : "Limited sale",
not_sale_only : "Unlimited",
sale_only : "Only buy 1",
auth_server : "Registration service",
hostname : "Host name",

unauth : "Not authorized",
ver_code : "version number",
force_update : "Force update",
force : "Mandatory",
force_update_select : "Update method",
user_select : "User selection",
submit_change : "Submit changes",
add_detail_info : "Add new detailed information",
oat_title : "Title",
oat_lang : "Language",
oat_url : "URL",
oat_content : "Description",
pls_input_app_id : "Please enter the APP ID",
pls_input_ver_code : "Please enter the version number",

other_lang : "Other languages",
en : "English",
"zh-CN" : "Simplified Chinese",
"zh-TW" : "Traditional Chinese",
language : "Language",
code : "Code",
tmp_type_1 : "Reset password",
tmp_type_2 : "Registration notification",
tmp_type_3 : "Activation notification",
tmp_type_4 : "Server operation and maintenance",
tmp_type_5 : "Registration verification code",
tmp_type_6 : "Modify password verification code",
tmp_type_7 : "Alarm notification",
tmp_type_8 : "System notification",
tmp_type_9 : "Device super password notification",
sms : "SMS",
// email : "Email",
msg : "Message",
template_type : "Template type",

add_sms_temp : "Add SMS template",
add_email_temp : "Add email template",
add_msg_temp : "Add message template",
edit_sms_temp : "Modify SMS template",
edit_email_temp : "Edit email template",
edit_msg_temp : "Edit message template",
add_sms_plat : "Add SMS platform",
add_email_plat : "Add email platform",
add_msg_plat : "Add messaging platform",
edit_sms_plat : "Modify SMS platform",
edit_email_plat : "Edit email platform",
edit_msg_plat : "Edit message platform",
smtp_addr : "SMTP address",
smtp_port : "SMTP port",
ssl_state : "SSL state",
one_push : "Push",
fcm : "FCM",
access_id : "Platform application ID",
access_key : "Platform application KEY",
secret_key : "Platform application Secret KEY",
// key_id : "Platform KEYID",
// key_secret : "Platform password",
// sign_name : "SMS signature",
pls_input_name : "Please enter the name",
pls_input_smtp_addr : "Please enter the SMTP address",
pls_input_smtp_port : "Please enter the SMTP port",
pls_input_ssl : "Please enter SSL",
pls_input_userid : "Please enter the user ID",
ssl : "SSL",
userid : "User ID",
body : "Content",
lang : "Language",
pls_input_body : "Please input content",
pls_input_code : "Please enter the code",
setting_email_bind : "Email binding",
setting_msg_bind : "Message binding",
setting_sms_bind : "SMS binding",
email_template : "Email template",
email_platform : "Email platform",
sms_template : "SMS template",
sms_platform : "SMS platform",
msg_platform : "Messaging platform",
// msg_platform : "Messaging template",
dont_bind : "Don't bind",

no_role_see : "No permission to see",


"South Sudan" : "South Sudan",
Somaliland : "Somaliland",
Kosovo : "Kosovo",
Vietnam : "Vietnam",
"West Bank" : "West Bank",
Afghanistan : "Afghanistan",
Angola : "Angola",
Albania : "Albania",
"United Arab Emirates" : "United Arab Emirates",
Argentina : "Argentina",
Armenia : "Armenia",
Australia : "Australia",
Austria : "Austria",
Azerbaijan : "Azerbaijan",
Burundi : "Burundi",
Belgium : "Belgium",
Benin : "Benin",
"Burkina Faso" : "Burkina Faso",
Bangladesh : "Bangladesh",
Bulgaria : "Bulgaria",
"The Bahamas" : "Bahamas",
"Bosnia and Herzegovina" : "Bosnia and Herzegovina",
Belarus : "Belarus",
Belize : "Belize",
Bermuda : "Bermuda",
Bolivia : "Bolivia",
Brazil : "Brazil",
Brunei : "Brunei",
Bhutan : "Bhutan",
Botswana : "Botswana",
"Central African Republic" : "Central African Republic",
Canada : "Canada",
Switzerland : "Switzerland",
Chile : "Chile",
China : "China",
"Ivory Coast" : "Republic of Ivory Coast",
Cameroon : "Cameroon",
"Democratic Republic of the Congo" : "Democratic Republic of the Congo",
"Republic of the Congo" : "Republic of the Congo",
Colombia : "Colombia",
"Costa Rica" : "Costa Rica",
Cuba : "Cuba",
"Northern Cyprus" : "Turkish Republic of Northern Cyprus",
Cyprus : "Cyprus",
"Czech Republic ": "Czech Republic",
Germany : "Germany",
Djibouti : "Djibouti",
Denmark : "Denmark",
"Dominican Republic" : "Dominican Republic",
Algeria : "Algeria",
Ecuador : "Ecuador",
Egypt : "Egypt",
Eritrea : "Eritrea",
Spain : "Spain",
Estonia : "Estonia",
Ethiopia : "Ethiopia",
Finland : "Finland",
Fiji : "Fiji",
France : "France",
Gabon : "Gabon",
UnitedKingdom : "United Kingdom",
Georgia : "Georgia",
Ghana : "Ghana",
Guinea : "Guinea",
Gambia : "Gambia",
"Guinea Bissau" : "Guinea Bissau",
Greece : "Greece",
Guatemala : "Guatemala",
"French Guiana" : "French Guiana",
Guyana : "Guyana",
Honduras : "Honduras",
Croatia : "Croatia",
Haiti : "Haiti",
Hungary : "Hungary",
Indonesia : "Indonesia",
India : "India",
Ireland : "Ireland",
Iran : "Iran",
Iraq : "Iraq",
Iceland : "Iceland",
Israel : "Israel",
Italy : "Italy",
Jamaica : "Jamaica",
Jordan : "Jordan",
Japan : "Japan",
Kazakhstan : "Kazakhstan",
Kenya : "Kenya",
Kyrgyzstan : "Kyrgyzstan",
Cambodia : "Cambodia",
"South Korea" : "South Korea",
Kuwait : "Kuwait",
Laos : "Laos",
Lebanon : "Lebanon",
Liberia : "Liberia",
Libya : "Libya",
"Sri Lanka" : "Sri Lanka",
Lesotho : "Lesotho",
Lithuania : "Lithuania",
Luxembourg : "Luxembourg",
Latvia : "Latvia",
Morocco : "Morocco",
Moldova : "Moldova",
Madagascar : "Madagascar",
Mexico : "Mexico",
Mali : "Mali",
Myanmar : "Myanmar",
Montenegro : "Montenegro",
Mongolia : "Mongolia",
Mozambique : "Mozambique",
Mauritania : "Mauritania",
Malawi : "Malawi",
Malaysia : "Malaysia",
Namibia : "Namibia",
"New Caledonia" : "New Caledonia",
Greenland : "Greenland",
"Falkland Islands" : "Malvinas Islands",
"French Southern and Antarctic Lands":  "French Southern and Antarctic Lands",
Macedonia : "Former South Macedonia",
Niger : "Niger",
Nigeria : "Nigeria",
Nicaragua : "Nicaragua",
Netherlands : "Netherlands",
Norway : "Norway",
Nepal : "Nepal",
"New Zealand" : "New Zealand",
Oman : "Oman",
Pakistan : "Pakistan",
Panama : "Panama",
Peru : "Peru",
Philippines : "Philippines",
"Papua New Guinea" : "Papua New Guinea",
Poland : "Poland",
"Puerto Rico" : "Puerto Rico",
"North Korea" : "North Korea",
Portugal : "Portugal",
Paraguay : "Paraguay",
Qatar : "Qatar",
Romania : "Romania",
Russia : "Russia",
Rwanda : "Rwanda",
"Western Sahara" : "Western Sahara",
"Saudi Arabia" : "Saudi Arabia",
Sudan : "Sudan",
Senegal : "Senegal",
"Solomon Islands" : "Solomon Islands",
"Sierra Leone" : "Sierra Leone",
"El Salvador" : "El Salvador",
Somalia : "Somalia",
"Republic of Serbia" : "Serbia",
Suriname : "Suriname",
Slovakia : "Slovakia",
Slovenia : "Slovenia",
Sweden : "Sweden",
Swaziland : "Swaziland",
Syria : "Syria",
Chad : "Chad",
Togo : "Togo",
Thailand : "Thailand",
Tajikistan : "Tajikistan",
Turkmenistan : "Turkmenistan",
"East Timor" : "East Timor",
"Trinidad and Tobago" : "Trinidad and Tobago",
Tunisia : "Tunisia",
Turkey : "Turkey",
"United Republic of Tanzania" : "Tanzania",
Uganda : "Uganda",
Ukraine : "Ukraine",
Uruguay : "Uruguay",
"United States" : "United States",
Uzbekistan : "Uzbekistan",
Venezuela : "Venezuela",
Vanuatu : "Vanuatu",
Yemen : "Yemen",
"South Africa" : "South Africa",
Zambia : "Zambia",
Zimbabwe : "Zimbabwe",
Liechtenstein : "Liechtenstein",
"W. Sahara" : "W. Sahara",
Serbia : "Serbia",
Aland : "Aland",
Andorra : "Andorra",
AmericanSamoa : "American Samoa",
CaymanIs : "Cayman Islands",
CI : "Cayman Islands",
VN : "Vietnam",
AF : "Afghanistan",
AO : "Angola",
AL : "Albania",
AE : "United Arab Emirates",
AR : "Argentina",
AM : "Armenia",
AU : "Australia",
AT : "Austria",
AZ : "Azerbaijan",
BI : "Burundi",
BE : "Belgium",
BJ : "Benin",
BF : "Burkina Faso",
BD : "Bangladesh",
BG : "Bulgaria",
BS : "Bahamas",
BA : "Bosnia and Herzegovina",
BY : "Belarus",
BZ : "Belize",
BM : "Bermuda",
BO : "Bolivia",
BR : "Brazil",
BN : "Brunei",
BT : "Bhutan",
BW : "Botswana",
CF : "Central African Republic",
CA : "Canada",
CH : "Switzerland",
CL : "Chile",
CN : "China",
KT : "Republic of Côte d'Ivoire",
CM : "Cameroon",
CD : "Democratic Republic of the Congo",
CG : "Republic of Congo",
CO : "Colombia",
CR : "Costa Rica",
CU : "Cuba",
TR : "Turkish Republic of Northern Cyprus",
CY : "Cyprus",
CZ : "Czech Republic",
DE : "Germany",
DJ : "Djibouti",
DK : "Denmark",
DO : "Dominican Republic",
DZ : "Algeria",
EC : "Ecuador",
EG : "Egypt",
ER : "Eritrea",
ES : "Spain",
EE : "Estonia",
ET : "Ethiopia",
FI : "Finland",
FJ : "Fiji",
FR : "France",
GA : "Gabon",
GB : "United Kingdom",
GE : "Georgia",
GH : "Ghana",
GN : "Guinea",
GM : "Gambia",
GW : "Guinea-Bissau",
GR : "Greece",
GT : "Guatemala",
GF : "French Guiana",
GY : "Guyana",
HN : "Honduras",
HR : "Croatia",
HT : "Haiti",
HU : "Hungary",
ID : "Indonesia",
IN : "India",
IE : "Ireland",
IR : "Iran",
IQ : "Iraq",
IS : "Iceland",
IL : "Israel",
IT : "Italy",
JM : "Jamaica",
JO : "Jordan",
JP : "Japan",
KZ : "Kazakhstan",
KE : "Kenya",
KG : "Kyrgyzstan",
KH : "Cambodia",
KR : "South Korea",
KW : "Kuwait",
LA : "Laos",
LB : "Lebanon",
LR : "Liberia",
LY : "Libya",
LK : "Sri Lanka",
LS : "Lesotho",
LT : "Lithuania",
LU : "Luxembourg",
LV : "Latvia",
MA : "Morocco",
MD : "Moldova",
MG : "Madagascar",
MX : "Mexico",
ML : "Mali",
MM : "Myanmar",
ME : "Montenegro",
MN : "Mongolia",
MZ : "Mozambique",
MR : "Mauritania",
MW : "Malawi",
MY : "Malaysia",
NA : "Namibia",
NC : "New Caledonia",
GL : "Greenland",
FK : "Malvinas Islands",
TF : "French Southern Territory",
MK : "Former South Macedonia",
NE : "Niger",
NG : "Nigeria",
NI : "Nicaragua",
NL : "Netherlands",
NO : "Norway",
NP : "Nepal",
NZ : "New Zealand",
OM : "Oman",
PK : "Pakistan",
PA : "Panama",
PE : "Peru",
PH : "Philippines",
PG : "Papua New Guinea",
PL : "Poland",
PR : "Puerto Rico",
KP : "North Korea",
PT : "Portugal",
PY : "Paraguay",
QA : "Qatar",
RO : "Romania",
RU : "Russia",
RW : "Rwanda",
EH : "Western Sahara",
SA : "Saudi Arabia",
SD : "Sudan",
SN : "Senegal",
SB : "Solomon Islands",
SL : "Sierra Leone",
SV : "El Salvador",
SO : "Somalia",
RS : "Serbia",
SR : "Suriname",
SK : "Slovakia",
SI : "Slovenia",
SE : "Sweden",
SZ : "Swaziland",
SY : "Syria",
TD : "Chad",
TG : "Togo",
TH : "Thailand",
TJ : "Tajikistan",
TM : "Turkmenistan",
TL : "East Timor",
TT : "Trinidad and Tobago",
TN : "Tunisia",
// TR : "Turkey",
TZ : "Tanzania",
UG : "Uganda",
UA : "Ukraine",
UY : "Uruguay",
US : "United States",
UZ : "Uzbekistan",
VE : "Venezuela",
VU : "Vanuatu",
YE : "Yemen",
ZA : "South Africa",
ZM : "Zambia",
ZW : "Zimbabwe",
SG : "Singapore",
Singapore : "Singapore",
cny_fee : "Transaction amount (¥)",
usd_fee : "Transaction amount ($)",
order_count : "Total number of orders",
unknown_zone : "Unknown zone",
comp_top5 : "Company order TOP5",
app_top5 : "APP order TOP5",
pey_type_rate : "Percentage of payment method",
comp_info_before : "Recent data",
cny : "RMB",
usd : "USD",
total_count : "Order",
app_detail : "APP detail",
comp_detail : "Company details",
ver_name : "version name",
pls_input_ver_name : "Please enter the version name",
oat_zone : "Zone",
other_zone : "Other zone",
pending : "Pending",
solved : "Solved",
send_desc : "Send details",
link_param : "Link parameter",
msg_param : "Information parameter",
is_succ : "Success",
is_fail : "Failed",
// link_id : "Linked data",
email_address : "Email address",
import_date : "Import date",
un_bind : "Unbind",
bind_other_order_id : "Bind a third party",
add_platform : "Add platform",
edit_platform : "Edit platform",
quectel : "Move away",
app_secret : "APP secret",
setting_quectel_bind : "Bind move away",
// platform_type : "Platform type",
setting_flow : "Flow configuration",
is_outfo_date : "Expired",
is_useing : "Is using",
end_time : "End time",
file : "File",
select_file : "Select file",
effect_time : "Effective time",
un_use : "Not used",
valid_renewa : "valid renewa",
dev_import : "Import records",
comp_short_name : "Company short name",
app_version : "APP version",
un_open : "No open",
save : "Save",
add_new : "Add new",
download_url : "Download url",
now_use_version : "Version in use",
none : "None",
histroy_version : "Histroy version",
real_qrcode_url : "Real qrcode url",
image_download : "Image download",
qrcode : "QR code",
set_app_download : "Set Download Url",
view_qrcode : "QR code Preview",
year : "Yaer",
qrcode_info : "After the user scans the code, the system will automatically jump to the corresponding download address according to the user's mobile phone system environment",
menu_cn_name : "Chinese Name",
edit_detail : "Modify detail",
add_new_app_set : "Add New APP Set",
payed : "has payed",
umeng : "UMeng",
no_active : "not active",
is_active : "active",
ser_sys : "service system",
fact_price : "Actual collection",

company_info : "company info",
fri_active_time : "first active time",
last_online_time : "last log time",
outline : "outline",
setting_server : "set server",
grpc_addr : "GRCP address",
// http_addr : "HTTP address",
reg_timestamp : "region time",
latest_timestamp : "last update time",
MP : "Mariana Islands",
HK : "HongKong",
BH : "Bosnia and Herzegovina",
TW : "Taiwan",
MO : "Macao",
app_id_or_name : "APP id or APP name",
bind_state : "Bind state",
is_bind : "Is binding",
unbind : "Bound",
channel : "Unbound",
you_will_delete : "Are you sure you want to unbind this device",

user_flow    : "User trends",
new_people   : "New people",
log_people   : "Number of people registered",
total_people : "Total people",
top_ten_zone : "Top 10 countries/regions",
pls_input_ver_code_num : "pls input number by version code",
today : "today",
yesterday : "yesterday",
memory_rate : "used memory",
disk_rate : "used disk",
info_email : "Notice Email",
un_overdue : "Unexpired",
dev_sys : "Belong sys",
dev_flow : " Device trends",
new_dev  : "New device",
log_dev : "Number of device registered",
total_dev : "Total people",
  },
}