<template>
  <div>
    <Menu></Menu>
    <div class="main-box ">
      <div class="earth-row">
        <div>
          <h1>科缔云服务</h1>
          <p>稳定的P2P穿透技术，专业的物联云平台，完善的产品服务，欢迎您的加入</p>
          <p><b><a href="/cloud">+了解更多</a></b></p>
          <div style="height: 48px; "></div>
        </div>
        <div class="earth-row-img">
          <img src="/img/img/index.png">
        </div>
      </div>
    </div>
    <div class="introduce">
      <div class="int-title-box">
        <h2 class="int-title">产品服务</h2>
        <p class="int-sub-title">
          深耕行业多年，我们已发展为全球领先的 IoT 音视频平台。专业为您提供完善的产品服务
        </p>
      </div>
      <div class="int-flex-box">
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">硬件接入</h3>
              <p class="int-flex-box-item-info">
                提供设备端sdk，厂家通过sdk及开发文档自主接入云，我们提供技术支持。
              </p>
              <p><b><a href="/hardware">+了解更多</a></b></p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/001.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">APP开发</h3>
              <p class="int-flex-box-item-info">
                提供免费公版APP和OEM定制服务。我们可以根据需求定制开发App，有开发能力的厂商可通过SDK及API文档自己开发APP。
              </p>
              <p><b><a href="/app">+了解更多</a></b></p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/002.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">服务部署</h3>
              <p class="int-flex-box-item-info">
                提供免费公有云和搭建私有云服务。根据客户需求使用公共服务器或者提供服务器给我们部署，构建自己的云平台。
              </p>
              <p><b><a href="/service">+了解更多</a></b></p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/003.png">
            </div>
          </div>
        </div>
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">方案&案例</h3>
              <p class="int-flex-box-item-info">
                主要为DVR/NVR/IPC等视频监控设备生产商提供音视频流解决方案。
              </p>
              <p><b><a href="/case">+了解更多</a></b></p>
            </div>
            <div class="int-flex-box-img">
              <img src="/img/img/004.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Menu from "@/pages/Dashboard/Menu.vue";
import Foot from "@/pages/Dashboard/Foot.vue";
export default {
  components:{
    Menu : Menu,
    Foot : Foot,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods : {
  }
};
</script>
<style scoped>
  a{
    text-decoration: none;
  }
  b{
    color: #606266 !important;
  }
  .int-flex-box-img img{
    width: 240px;
  }
  .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    font-family: "PingFang SC";
    margin: 0;
  }
  .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .int-flex-box-img{
    max-width: 240px;
    min-width: 240px;
  }
  .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 54px 16px 54px 40px;
    flex-direction: column;
  }
  .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    height: 100%;
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
  }
  .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .int-flex-box{
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    /*align-items: center;*/
    align-items: stretch;
  }
  .int-title-box{
    text-align: center;
    padding: 0 12px 60px;
  }
  .int-sub-title{
    margin-top:24px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-bottom: 0;
  }
  .int-title{
    line-height: 50px;
    height: 50px;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
  }
  div.introduce {
    margin: 72px auto;
    /* margin: 72px 0; */
    padding: 0 72px;
    max-width: 1200px;
  }
  .earth-row{
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    height: 500px;
  }
  .earth-row img{
    width: 100%;
  }
  .earth-row p{
    color: #606266;
    line-height: 26px;
  }

  .earth-row>div{
    align-items:center;
    padding: 0 12px;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .main-box p{
    margin: 32px 0 42px;
    font-size: 16px;
  }
  .main-box h1{
    line-height: 66px;
    font-size: 56px;
    margin: 0;
    /*margin-top: 64px;*/
  }
  .main-box{
    box-shadow: inset 0 0 50px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    height: 500px;
    width: 100%;
    /*padding: 0 72px;*/
    margin: 0 auto;
    background-color: #eef2f6;
    clear: both;
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  @media screen and (max-width: 1023px){
    div.introduce{
      padding: 0 32px;
    }
    .init-title-box{
      height: 150px;
    }
    .main-box p {
      margin: 24px 0;
    }
    .main-box {
      padding: 0 12px;
      height: unset;
    }
    .earth-row{
      height: unset;
    }
    .earth-row>div{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 80px 0 36px;
    }
    .earth-row-img{
      display: none;
    }
    .main-box h1{
      font-size: 36px;
    }
    .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
    .int-flex-box-span {
      padding: 48px 26px 0;
    }
  }
</style>