import Index from "@/pages/Dashboard/Index.vue";
import CloudInfo from "@/pages/Dashboard/CloudInfo.vue";
import Introduce from "@/pages/Dashboard/Introduce.vue";
import AddServer from "@/pages/Dashboard/AddServer.vue";

import Hardware  from "@/pages/Dashboard/Hardware.vue";
import App       from "@/pages/Dashboard/App.vue";
import Service   from "@/pages/Dashboard/Service.vue";
import Case      from "@/pages/Dashboard/Case.vue";

let authPages = {
  path: "/index",
  component: Index,
  name: "Index",
};


const routes = [
  {
    path: "/",
    component: Index,
    name: "Index",
  },
  {
    path: "/cloud",
    component: CloudInfo,
    name: "Cloud",
  },{
    path: "/hardware",
    component: Hardware,
    name: "Hardware",
  },{
    path: "/app",
    component: App,
    name: "App",
  },{
    path: "/service",
    component: Service,
    name: "Service",
  },{
    path: "/case",
    component: Case,
    name: "Case",
  },{
    path: "/introduce",
    component: Introduce,
    name: "Introduce",
  },{
    path: "/addserver",
    component: AddServer,
    name: "AddServer",
  },

  // {
  //   path: "/cloudcmposition",
  //   component: CloudInfo,
  //   name: "Index",
  // },
  // {
  //   path: "/cloudfield",
  //   component: CloudInfo,
  //   name: "Index",
  // },
  // {
  //   path: "/cloudcase",
  //   component: CloudInfo,
  //   name: "Index",
  // },
  authPages,
];

export default routes;
