import { JSEncrypt } from "jsencrypt"
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDwg13VOsZIA3tzF1pIevDIbdUj"+
"7ob+lbki0XpHQ+OJR2kjAx0IVn0CfvVUoOzQ2uIgjNQSxcojaxYYIydtmeBmLcKK"+
"fnhEvGfIzUzlBUAyTSzEHhynlSJVmpeodY6pznnDOkCPOtREes7w+VN019l5FFCA"+
"DCqZLf6ZJxbi0N7TDwIDAQAB"

var dateYYYYMMDD = function(dateString) {
    var list = dateString.split("-")
    if (list[1].length == 1){
        list[1] = "0" + list[1]
    }
    if (list[2].length == 1){
        list[2] = "0" + list[2]
    }
    return list.join("-")
}
// 判断是否是数组
function isArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}

//判断是否是JSON(返回Boolean)
function isJson(param) {
    try {
        return (Object.prototype.toString.call(param) === "[object Object]")
    } catch(e) {
        console.log(param)
    }
    //console.log("不是json")
    return false;
}
// 保留2位小数
export function toDecimal(x) {   
    var f = parseFloat(x);   
    if (isNaN(f)) {   
        return;   
    }   
    f = Math.round(x*100)/100;   
    return f;   
}   
//返回一个obj的复制(返回obj)
export function copyObj (oldObj){
    var obj;
    if ((isJson(oldObj) && (obj = new Object())) || (isArray(oldObj) && (obj = new Array()))){
        if(oldObj.Copy){
            console.log("Y")
            obj = oldObj.Copy()
        } else {
            for(var i in oldObj){
                obj[i] = (isJson(oldObj[i]) || isArray(oldObj[i]))
                    ? copyObj(oldObj[i])
                    : oldObj[i];
            }
        }
    }
    return obj;
}
// 获取用户的所在的地区的语言
export function getLanguage (){
    var currentLang;  
    currentLang = navigator.language;  
    if(!currentLang)
        currentLang = navigator.browserLanguage;
    if(currentLang == 'zh-CN' || currentLang === 'zh-cn'){  //中文 环境 
        currentLang = 'zh';
        // currentLang = 'en';
    }else{
        currentLang = 'en';     // 英文 环境
    }
    return currentLang;
}
//获取操作系统版本
export function detectOS() {
    var sUserAgent = navigator.userAgent;
    var isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
    var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
    if (isMac) return "Mac";
    var isUnix = (navigator.platform == "X11") && !isWin && !isMac;
    if (isUnix) return "Unix";
    var isLinux = (String(navigator.platform).indexOf("Linux") > -1);
    if (isLinux) return "Linux";
    if (isWin) {
        var isWin2K = sUserAgent.indexOf("Windows NT 5.0") > -1 || sUserAgent.indexOf("Windows 2000") > -1;
        if (isWin2K) return "Win2000";
        var isWinXP = sUserAgent.indexOf("Windows NT 5.1") > -1 || sUserAgent.indexOf("Windows XP") > -1;
        if (isWinXP) return "WinXP";
        var isWin2003 = sUserAgent.indexOf("Windows NT 5.2") > -1 || sUserAgent.indexOf("Windows 2003") > -1;
        if (isWin2003) return "Win2003";
        var isWinVista= sUserAgent.indexOf("Windows NT 6.0") > -1 || sUserAgent.indexOf("Windows Vista") > -1;
        if (isWinVista) return "WinVista";
        var isWin7 = sUserAgent.indexOf("Windows NT 6.1") > -1 || sUserAgent.indexOf("Windows 7") > -1;
        if (isWin7) return "Win7";
        var isWin10 = sUserAgent.indexOf("Windows NT 10") > -1 || sUserAgent.indexOf("Windows 10") > -1;
        if (isWin10) return "Win10";
    }
    return "other";
}

export function nPw (user_id , user_passwd){
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    var doBefore = user_id + "\r\n" + user_passwd + "\r\n" + parseInt(new Date().getTime()/1000);
    var pw = encrypt.encrypt(doBefore)
    return pw;
}


// n天前
export function nDaysAgo (n, startDate){
    var date = new Date(new Date().getTime() - 1000* 60* 60* 24* n);
    if(startDate) {
        date = new Date(new Date(startDate).getTime() - 1000* 60* 60* 24* n);
    }
    return dateYYYYMMDD(date.getFullYear() + "-" + (date.getMonth()+1 % 12) + "-" + date.getDate());
}
// n个月前 (的1号)
export function nMonthsAgo (n, startDate){
    if(startDate){
        return dateYYYYMMDD(new Date(new Date(new Date().setDate(1)).setMonth((new Date(startDate)).getMonth() - n)).toLocaleDateString().replace(/\//g,"-"))
    }
    return dateYYYYMMDD(new Date(new Date(new Date().setDate(1)).setMonth((new Date()).getMonth() - n)).toLocaleDateString().replace(/\//g,"-"))
}
export function nDateRound (start, end, type){ // type = 1 :按1天跨度, type =2 按1个月跨度
    var result =[]
    if(type == 1){
        var n = (new Date(end) - new Date(start))/(1000*60*60*24)
        for(let i =0 ; i <= n; i++){
            result.push(nDaysAgo(0-i, start))
        }
    } else if (type ==2){
        for(let i =0 ; new Date(nMonthsAgo(0-i,start)) < new Date(nMonthsAgo(-1,end)) ; i++) {
            var list = nMonthsAgo(0-i,start).split("-")
            result.push(list[0]+"-"+list[1])
        }
    }
    return result
}
export function timestampToString (stamp) {
    var date = new Date(stamp);
    
    return date.getFullYear() + "-" + 
        (date.getMonth()+1<=9 ? "0": "") + (date.getMonth()+1) + "-" + 
        (date.getDate() <=9 ?"0" :"") + date.getDate() + " " + 
        (date.getHours() <= 9 ? "0" :  "") + date.getHours() + ":" + 
        (date.getMinutes() <= 9 ? "0" :"") + date.getMinutes() + ":" + 
        (date.getSeconds() <= 9 ? "0" :"") + date.getSeconds()
    // return dateYYYYMMDD(date.getFullYear() + "-" + (date.getMonth()+1 % 12) + "-" + date.getDate());
}