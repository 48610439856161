// =========================================================
// * Vue Material Dashboard PRO - v1.5.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueRouter from "vue-router";
// import DashboardPlugin from "./material-dashboard";
// import Notifications from './components/NotificationPlugin'
// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// router setup
import routes from "./routes/routes";

import VueI18n from "vue-i18n";

import zh from "./common/lang/zh";
import api from "@/common/interface.js";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueRouter);
import {DatePicker, Transfer, Icon } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '/public/css/element-variables.scss'

Vue.use(ElementUI);
Vue.use(DatePicker);
Vue.use(Transfer);
Vue.use(Icon);
Vue.use(VueI18n) // 通过插件的形式挂载

const i18n = new VueI18n({
    locale: 'zh-CN',    // 语言标识
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
      'zh-CN': zh,   // 中文语言包
    }
})
// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
});

// global library setup
Vue.prototype.$Chartist = Chartist;
// I18N 国家化
Vue.prototype.locale = "zh-CN"
Vue.prototype.$I18n = function(key){
  var lang = "zh"
  if (router.history.current.query.lang === "en"){
    lang = "en"
  }
  let res = i18n.t(`${lang}.${key}`)
  if (arguments.length === 1){
    return res;
  } else if(arguments.length > 1){
    for(let i = 1; i < arguments.length; i++){
      res = res.replace(`%${i}%`, arguments[i]);
    }
    return res;
  } else {
    return "";
  }
}
Vue.prototype.$En = function(key){
  var lang = "en"
  let res = i18n.t(`${lang}.${key}`)
  if (arguments.length === 1){
    return res;
  } else if(arguments.length > 1){
    for(let i = 1; i < arguments.length; i++){
      res = res.replace(`%${i}%`, arguments[i]);
    }
    return res;
  } else {
    return "";
  }
}
// API
Vue.prototype.$api = {}
for (let key in api) {
  
  Vue.prototype.$api[key] = function(data){
    return api[key](data)
      .catch (err => {
        if(err.message === "http_error_514"){
          Vue.prototype.userInfo = {};
          router.push("/login");
        }
        throw err
      })
  }
}

/* eslint-disable no-new */
new Vue({
  el: "#app",
  // i18n,
  render: (h) => h(App),
  router,
  watch : {
    // 如果切换语言则刷新页面
    "$route" (to, from){
      if (to.query.lang !== from.query.lang) {
        router.go(0)
      }
    },
  }
});
