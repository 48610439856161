<template>
  <div>
    <Menu></Menu>
    <!-- 弹窗 -->
    <el-dialog width="400px" title="" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="设备ID" :label-width="formLabelWidth">
          <div style="border: 1px solid #BBBBBB;border-radius: 5px;padding-left: 15px;">
            <el-input v-model="form.uid" autocomplete="off"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="用户ID" :label-width="formLabelWidth">
          <div style="border: 1px solid #BBBBBB;border-radius: 5px;padding-left: 15px;">
            <el-input v-model="form.user" autocomplete="off"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="APP ID" :label-width="formLabelWidth">
          <div style="border: 1px solid #BBBBBB;border-radius: 5px;padding-left: 15px;">
            <el-input v-model="form.appid" autocomplete="off"></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="go_url">确 定</el-button>
      </div>
    </el-dialog>
    <div class="introduce" >
      <div class="int-flex-box">
        <div class="int-flex-box-item">
          <div>
            <div class="int-flex-box-span">
              <h3 class="int-flex-box-item-title">云存储服务</h3>
              <p class="int-flex-box-item-info">
                <b>上传云端</b> 移动视频上传<br/>
                <b>无限空间</b> 超大容量存储空间<br/>
                <b>安全放心</b> 多重保障<br/>
              </p>
              <p><b @click="dialogFormVisible = true" style="font-size:18px; color: #0261ff; cursor: pointer;">购买</b></p>
            </div>
            <div class="int-flex-box-img" style="background-size: cover;background-image: url('/img/img/002.jfif');">
              <!-- <img style="margin-bottom: -4px;" src="/img/img/002.jfif"> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Menu from "@/pages/Dashboard/Menu.vue";
import Foot from "@/pages/Dashboard/Foot.vue";
export default {
  components:{
    Menu : Menu,
    Foot : Foot,
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        uid   :"",
        user  :"",
        appid :"",
      },
      formLabelWidth: '120px'
    };
  },
  mounted() {
  },
  methods : {
    go_url(){
      window.location.href = `https://h5-kdzn-cn.kdzn.top/shopping/product.html?pt=101&uid=${this.form.uid}&appid=${this.form.appid}&user=${this.form.user}&lang=2&devname=dev&channel=1`
    },
  }
};
</script>
<style scoped>
  .case-box .int-flex-box-item-info a{
    color: #4d85ff;
    font-weight: 600;
    text-decoration: none;
  }
  .case-box .int-flex-box-img img{
    /*width: auto;*/
    width: 32px;
    height: 32px;
  }
  .case-box .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .case-box .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .case-box .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .case-box .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .case-box .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 16px;
    flex-direction: column;
    padding-left: 0;
  }
  .case-box .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .case-box .int-flex-box-item>div{
    align-items: flex-start;
    display: flex;
    background-color: rgb(255, 255, 255);
    height: 172px;
    /*height: 100%;*/
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
  }
  .case-box .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .case-box .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    box-sizing: border-box;
  }
  .case-box.int-flex-box{
    /*padding-bottom: 72px;*/
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: stretch;

    justify-content: center;
  }
  .case-box .int-flex-box-item.thr-box>div{
    display: flex;
    flex-direction: column;
    padding: 48px 32px 0px;
  }
  .case-box .int-flex-box-item.thr-box{
    max-width:  33%;
    flex-basis: 33%;
  }
  div.introduce{
    margin: 72px auto;
    /*margin: 72px 0;*/
    padding: 0 72px;
    max-width: 1200px;
  }
  .advantage .int-flex-box-img{
    max-width: 200px;
    min-width: 200px;
  }
  .advantage .int-flex-box-img img {
    width: 200px;
  }
  .info-item-box p{
    margin: 0 0 20px 0;
  }
  .int-flex-box-list-item{
    align-items: stretch !important;
    flex-direction: row !important;
  }
  .info-item-box img{
    margin: 42px 0;
  }
  .info-item-box{
    box-sizing: border-box;
    padding: 58px 36px 48px;
    background-color: #ffff;
    flex-basis: 60%;
    flex-grow: 1;
    flex-shrink: 0;
    /*max-width: ;*/
  }
  .list-item-box{
    box-sizing: border-box;
    padding: 58px 0;
    background-color: #f6fafe;
    flex-basis: 40%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 360px;
    min-height: 600px;
  }
  .list-item-box .list-item-list {
    cursor: pointer;
    padding: 0 54px;
    line-height: 48px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  .list-item-box .list-item-list.selected {
    background: #025dff;
    color: #ffffff;
  }
  .int-flex-box-half .int-flex-box-item-title{
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 36px;
    height: unset;
  }
  .int-flex-box-half.img{
    text-align: center;
  }
  img{
    max-width: 100%;
  }
  .int-flex-box-half{
    box-sizing: border-box;
    padding: 0 36px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .int-flex-box-img img{
    width: 300px;
  }
  .int-flex-box-item-info p{
    margin: 4px 0;
  }
  .int-flex-box-item-info{
    font-size: 16px;
    font-family: "PingFang SC";
    line-height: 26px;
    color: rgb(96,98,102);
    margin: 0;
  }
  .int-flex-box-item-title{
    font-size: 20px;
    height: 30px;
    margin: 0;
    margin-bottom: 12px;
  }
  .int-flex-box-img{
    max-width: 300px;
    min-width: 300px;
  }
  .int-flex-box-span{
    display: flex;
    flex: 1 1 auto;
    padding: 54px 16px 54px 40px;
    flex-direction: column;
  }
  .int-flex-box-item>div:hover{
    top: -10px;
    box-shadow: 0 -2px 10px 0 hsl(225deg 3% 46% / 3%), 0 14px 20px 0 hsl(225deg 3% 46% / 18%);
  }
  .int-flex-box-item.no-move>div:hover{
    top: 0;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
  }
  .int-flex-box-item.no-border>div{
    border: 0 !important;
    box-shadow: none !important;
  }
  .int-flex-box-item>div{
    display: flex;
    background-color: rgb(255, 255, 255);
    min-height: 240px;
    height: 100%;
    margin: 0px auto;
    box-shadow: 0 4px 15px 0 rgb(37 60 126 / 12%);
    border-radius: 8px;
    position: relative;
    transition: top .3s, box-shadow .3s;
    top: 0;
    justify-content: center;
/*    align-items: center;*/
  }
  .int-flex-box-item.full-box{
    max-width: 100%;
    flex-basis: 100%;
  }
  .int-flex-box-item{
    padding: 12px;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .int-flex-box{
    margin-left: -12px;
    margin-right: -12px;
    margin-top: -12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: center;
  }
  .int-title-box{
    text-align: center;
    padding: 0 12px 60px;
  }
  .int-sub-title{
    margin-top:24px;
    color: rgb(96, 98, 102);
    font-size: 16px;
    line-height: 26px;
    height: 26px;
    margin-bottom: 0;
  }
  .int-title{
    line-height: 50px;
    height: 50px;
    margin: 0;
    font-size: 40px;
    font-weight: 700;
  }
  .earth-row{
    max-width: 1300px;
    display: flex;
    margin: 0 auto;
    height: 500px;
  }
  .earth-row img{
    width: 100%;
  }
  .earth-row p{
    color: #606266;
    line-height: 26px;
  }

  .earth-row>div{
    align-items:center;
    padding: 0 12px;
    width: 50%;
    align-self: center;
    box-sizing: border-box;
    /*padding-left: 12px;*/
    /*padding-right: 12px;*/
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
  }
  .main-box p{
    margin: 32px 0 42px;
    font-size: 16px;
  }
  .main-box h1{
    line-height: 66px;
    font-size: 56px;
    margin: 0;
    /*margin-top: 64px;*/
  }
  .main-box{
    box-shadow: inset 0 0 50px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    height: 500px;
    width: 100%;
    /*padding: 0 72px;*/
    margin: 0 auto;
    background-color: #eef2f6;
    clear: both;
    background-repeat: no-repeat;
    background-size: cover;
    flex-wrap: wrap;
    font-family: PingFang-SC-Regular, "PingFang SC", "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
  }
  @media screen and (max-width: 1023px){
    .case-box .int-flex-box-item.thr-box{
      max-width: 100%;
      flex-basis: 100%;
    }
  .case-box .int-flex-box-item.thr-box>div{
    align-items: flex-start;
    min-height: 80px;
  }
    div.introduce{
      padding: 0 32px;
    }
    .int-flex-box-half {
        box-sizing: border-box;
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
        flex-grow: 0;
        flex-shrink: 0;
    }
    .info-item-box{
      padding: 12px 36px;
    }
    .list-item-box{
      padding: 12px 0;
    }
    .init-title-box{
      height: 150px;
    }
    .main-box p {
      margin: 24px 0;
    }
    .main-box {
      padding: 0 12px;
      height: unset;
    }
    .earth-row{
      height: unset;
    }
    .earth-row>div{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      padding: 80px 0 36px;
    }
    .earth-row-img{
      display: none;
    }
    .main-box h1{
      font-size: 36px;
    }
    .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 1023px){
    .case-box .int-flex-box-item{
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
    .case-box .int-flex-box-item>div{
      flex-direction: column;
      align-items: center;
    }
  }
</style>